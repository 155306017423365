import * as React from 'react';
import './profile-page.component.css';
import Page from "../../../components/core/Page";

import {Customer, Profile} from "../../../models";
import Input, {FormFieldInputs} from "../../../components/input/Input";
import FormSubmitButton from "../../../components/common/FormSubmitButton";
import {IonButton, IonLabel, IonSegment, IonSegmentButton, IonToolbar} from "@ionic/react";


type ProfilePageProps = {
    profile?: Profile|null;
    customer?: Customer|null;
    selectedId?: any;

    profileForm: any;
    profileFormFieldInputs: FormFieldInputs;
    onSubmitProfile: (data: any) => void;
    onBackClicked: () => void;
    onSelectProfile: (profileId: number) => void;

    // Loading states
    isFetching: boolean;
    isLoading: boolean;
};
export const ProfilePage: React.FC<ProfilePageProps> = (props) => {
    const {
        profileForm,
        profileFormFieldInputs,
        profile,
        onSelectProfile,
        customer,
        onSubmitProfile,
        onBackClicked,
        isFetching,
        isLoading,
    } = props;
    const { handleSubmit, register, control, formState: { errors } } = profileForm;

    const formRef = React.useRef<HTMLFormElement>(null);

    const triggerSave = () => {
        if (formRef.current) {
            formRef.current.requestSubmit();
        }
    };

    return (
        <Page
            className={"profile-page ion-text-center"}
            title={`${profile?.fullName}`}
            showBackButton={true}
            showMenuButton={false}
            showLoading={isFetching}
            topRightButton={<IonButton className="ion-color text-semi-bold" slot="end" fill="clear" onClick={() => triggerSave()}>Save</IonButton>}
        >
            <>
                {/* Navigation Links */}
                {customer?.profiles && customer?.profiles.length > 0 && customer?.profiles.length < 5 && (
                <IonToolbar>
                    <IonSegment value={profile?.id}>
                        {customer?.profiles
                            .map((menuProfile, index) => (
                                <IonSegmentButton
                                    key={index}
                                    onClick={() => onSelectProfile(menuProfile.id)}
                                    value={menuProfile.id}
                                >
                                    <IonLabel>{menuProfile.fullName}</IonLabel>
                                </IonSegmentButton>
                            ))}
                    </IonSegment>
                </IonToolbar>
                )}

                {/* Profile Edit Form */}
                <form ref={formRef} onSubmit={handleSubmit(onSubmitProfile)}>
                    {Object.entries(profileFormFieldInputs).map(([fieldName, field], index) => (
                        <Input
                            key={index}
                            {...field}
                            control={control}
                            register={register}
                            errors={errors}
                            isDisabled={isLoading}
                        />
                    ))}
                    <FormSubmitButton
                        outerClassName={"ion-text-center ion-margin-top"}
                        disabled={isLoading}
                    >
                        Save
                    </FormSubmitButton>
                </form>

                <IonButton className="ion-margin-bottom" onClick={onBackClicked} fill="clear">
                    Back
                </IonButton>
            </>
        </Page>
    );
};
