import React, {FC} from "react";
import './TabFooter.css'
import {IonButton, IonCol, IonFooter, IonGrid, IonIcon, IonRow, IonToolbar} from "@ionic/react";
import {calendar, newspaper, chatbubbles} from 'ionicons/icons';
import {Customer} from "../../models";

export interface TabFooterProps {
    activeRoute: string;
    customer: Customer;
}

const TabFooter: FC<TabFooterProps> = (props) => {

    const {
        activeRoute, customer
    } = props;

    const tabItems = [
        { route: "/home", icon: calendar, active: activeRoute === "/home" },
        {
            route: "/account",
            icon: newspaper,
            active: activeRoute === "/account",
            condition: !!customer.company.useBilling
        },
        {
            route: "/chat",
            icon: chatbubbles,
            active: activeRoute === "/chat",
            condition: !!customer?.company?.featuresChat
        }
    ];

    return (
        <IonFooter className={"tab-footer ion-text-center"}>
            <IonToolbar className="ion-no-padding">
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        {tabItems.filter(item => item.condition !== false).map((item, index) => (
                            <IonCol
                                key={index}
                                className={`tab-footer-button-holder ${item.active ? 'tab-footer-button-holder-active' : ''}`}
                            >
                                <IonButton
                                    className="tab-footer-button"
                                    routerLink={item.route}
                                    routerDirection="none"
                                    fill="clear"
                                    expand="full"
                                >
                                    <IonIcon
                                        icon={item.icon}
                                        size="large"
                                        className={`tab-footer-icon ${item.active ? 'tab-footer-icon-active' : ''}`}
                                    />
                                </IonButton>
                            </IonCol>
                        ))}
                    </IonRow>
                </IonGrid>
            </IonToolbar>
        </IonFooter>
    )
};

export default TabFooter;
