import React from 'react';
import './ProfileCard.css';
import {useAppSelector} from "../../../redux/hooks";
import {profilePackagesByProfileIdSelector} from "../../../redux/profile-packages";
import {
    IonAccordionGroup,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle
} from "@ionic/react";
import {Booking, Customer, Profile} from "../../../models";
import ProfilePackageAccordion from "../ProfilePackageAccordion/ProfilePackageAccordion";

export interface ProfileCardProps {
    profile: Profile;
    customer: Customer;

    setSelectedBooking: (booking?: Booking) => void;
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
    const {profile, customer, setSelectedBooking } = props;

    const profilePackagesByLearnerId = useAppSelector((state) => profilePackagesByProfileIdSelector(state, profile.id))

    return (
        <IonCard className={"ion-text-left profile-card"} key={`profile-${profile.id}`}>
            <IonCardHeader>
                <IonCardTitle className={'profile-card-title'}>
                    {profile.fullName}
                </IonCardTitle>
                {profile.level && <IonCardSubtitle>{profile.level.name}</IonCardSubtitle>}
            </IonCardHeader>
            <IonCardContent className={"ion-no-padding"}>
                {
                    <IonAccordionGroup>
                        {
                            profilePackagesByLearnerId && profilePackagesByLearnerId.map((profilePackage, index) => (
                                <ProfilePackageAccordion
                                    key={`profile-package-accordion-${profilePackage.id}`}
                                    profilePackage={profilePackage}
                                    setSelectedBooking={setSelectedBooking}
                                    useSelfBooking={customer?.company?.useSelfBooking}
                                    useClasses={customer?.company?.useClasses}
                                ></ProfilePackageAccordion>
                            ))
                        }
                    </IonAccordionGroup>
                }
            </IonCardContent>
        </IonCard>
    );
};

export default ProfileCard;
