import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonRouterOutlet, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import * as React from "react";
import {useEffect, useState} from "react";
import {Capacitor} from "@capacitor/core";


/* Stores */
import {useCustomerStore} from "./redux/customers";

/* Components */
import Menu from "./components/core/Menu";
import AppToast from "./components/core/AppToast";
import ApiConfigurator from "./components/core/ApiConfigurator";
import LogoutHandler from "./components/core/LogoutHandler";
import GuardedRoute from "./components/common/Routes/GuardedRoute";

/* Pages */
import {HomePage} from "./pages/home";
import {AboutPage} from "./pages/about";

import {AccountPage, DocumentPage} from "./pages/account";
import {CustomerSelectorPage} from "./pages/customer-selector";
import {CommunicationsPage} from "./pages/communications";
import {ChatPage} from "./pages/chat";
import {AuthLoginPage} from "./pages/auth/auth-login";
import {AuthForgotPage} from "./pages/auth/auth-forgot";
import {AuthRequestVerificationPage} from "./pages/auth/auth-request-verification";
import {AuthVerifyPage} from "./pages/auth/auth-verify";
import {SlotsPage} from "./pages/slots";
import {ProfilePage} from "./pages/profile";
import {useAuthStore} from "./redux/auth";
import NetworkChecker from "./components/core/NetworkChecker";
import PushListener from "./components/core/PushListener";
import {SplashScreen} from "@capacitor/splash-screen";
import {PrivacyPage} from "./pages/privacy";
import {TermsOfUsePage} from "./pages/terms-of-use";
import UserDeleteModal from "./components/users/UserDeleteModal/UserDeleteModal";
import {useUserStore} from "./redux/users";
import {CustomerPage} from "./pages/customer";
import {useSystemStore} from "./redux/system";
import {SettingsPage} from "./pages/settings";
import WebPushListener from "./components/core/WebPushListener";

setupIonicReact();

const App: React.FC = () => {
    const customerStore = useCustomerStore();
    const {selectedId, activeCustomer} = customerStore;
    const authStore = useAuthStore();
    const {isAuthed} = authStore;
    const userStore = useUserStore();
    const {isOpenDeleteModal, setIsOpenDeleteModal} = userStore;
    const systemStore = useSystemStore();
    const {enableAnimation} = systemStore;

    // Auth Controls
    const [mustLogout, setMustLogout] = useState<boolean>(false);
    const [mustLogoutWithoutApi, setMustLogoutWithoutApi] = useState<boolean>(false);

    useEffect(() => {
        SplashScreen.hide();
    }, []);

    return (
        <IonApp>
            <IonReactRouter>
                <ApiConfigurator setMustLogout={setMustLogout}/>
                <NetworkChecker />
                {isAuthed && (Capacitor.getPlatform() === 'web' ? <WebPushListener /> : <PushListener />)}
                <AppToast />
                <LogoutHandler mustLogout={mustLogout} setMustLogout={setMustLogout} mustLogoutWithoutApi={mustLogoutWithoutApi}/>
                <Menu setMustLogout={setMustLogout}/>
                    <IonRouterOutlet id="main" animated={enableAnimation}>
                        <GuardedRoute guestOnly path="/auth/login" exact component={AuthLoginPage}/>
                        <GuardedRoute guestOnly path="/auth/forgot" exact component={AuthForgotPage}/>
                        <GuardedRoute guestOnly path="/auth/request-verify" exact component={AuthRequestVerificationPage}/>
                        <GuardedRoute guestOnly path="/auth/verify" exact component={AuthVerifyPage}/>

                        <GuardedRoute path="/document/:id" exact redirectTo={!selectedId && "/customer-select"} component={DocumentPage}/>
                        <GuardedRoute path="/home" exact redirectTo={!selectedId && "/customer-select"} component={HomePage}/>
                        <GuardedRoute path="/account" exact redirectTo={!activeCustomer?.company?.useBilling && "/home"} component={AccountPage}/>
                        <GuardedRoute path="/slots/:id" exact redirectTo={!activeCustomer?.company?.useClasses && "/home"} component={SlotsPage}/>
                        <GuardedRoute path="/profile/:id" exact redirectTo={!activeCustomer?.company?.featuresSelfManage && "/home"} component={ProfilePage}/>
                        <GuardedRoute path="/customer" exact redirectTo={!activeCustomer?.company?.featuresSelfManage && "/home"} component={CustomerPage}/>

                        <GuardedRoute path="/customer-select" exact component={CustomerSelectorPage}/>
                        <GuardedRoute path="/chat" exact redirectTo={!activeCustomer?.company?.featuresChat && "/home"} component={ChatPage}/>
                        <GuardedRoute path="/notifications" exact component={CommunicationsPage}/>
                        <GuardedRoute path="/settings" exact component={SettingsPage}/>

                        <Route path="/active-customer" exact render={() => (selectedId ? <Redirect to="/home"/> : <Redirect to="/customer-select"/>)}/>
                        <Route path="/" exact render={() => <Redirect to={isAuthed ? "/home" : "/auth/login"} />}/>

                        {/*<Route path="*" component={Error404Page} />*/}
                    </IonRouterOutlet>
                    <AboutPage />
                    <PrivacyPage />
                    <TermsOfUsePage />
                    {isAuthed && <UserDeleteModal
                        setMustLogout={setMustLogoutWithoutApi}
                        isOpen={isOpenDeleteModal}
                        onClose={() => {
                            setIsOpenDeleteModal(false)
                        }}
                    />}
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
