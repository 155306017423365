import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { CustomerPage } from '../components';
import { useToastStore } from '../../../redux/toast';
import {
    formFieldInputs as customerFixedFormFieldInputs,
    FormFields as CustomerFormFields,
} from "../../../forms/customers/customer.form";
import {useCustomerStore} from "../../../redux/customers";
import {useHistory} from "react-router";
import {convertExtraAttributesToFormFields} from "../../../utils/convertExtraAttributesToFormFields";

type Props = {
};

export const CustomerPageContainer: React.FC<Props> = () => {
    const history = useHistory();
    const toastStore = useToastStore();
    const { showError, showSuccess, clear: toastClear } = toastStore;

    const customerStore = useCustomerStore();
    const {
        errorMessage,
        clearErrors: customerClearErrors,
        // getCustomer,
        update,
        activeCustomer: customer,
        isFetching,
    } = customerStore;

    const customerFormFieldInputs: any = {
        ...customerFixedFormFieldInputs,
        ...(customer ? convertExtraAttributesToFormFields(customer.extraAttributes) : []),
    };

    const customerForm = useForm<CustomerFormFields>({
        mode: 'onSubmit',
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            financialEmail: '',
        }
    });
    const { setValue, setError: formSetError, clearErrors: formClearErrors } = customerForm;

    useEffect(() => {
        if(customer){
            setValue("firstName", customer.firstName);
            setValue("lastName", customer.lastName);
            setValue("email", customer.email || '');
            setValue("financialEmail", customer.financialEmail || '');

            customer.extraAttributes.forEach(attr => {
                setValue(`extra_attribute_${attr.id}`, attr.value || '');
            });
        }
    }, [customer]);

    useEffect(() => {
        if (errorMessage) {
            toastClear().then(() => {
                showError({ message: errorMessage });
                formClearErrors();
                customerClearErrors();
            })
        }
    }, [errorMessage]);

    const onSelectProfile = async (profileId: number) => {
        history.replace(`/profile/${profileId}`);
    }

    const onSubmitCustomer = async (data: CustomerFormFields) => {
        await toastClear();
        const updateData: any = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            financial_email: data.financialEmail,
        };

        // Extract and prepare extra attributes
        if (customer?.extraAttributes?.length) {
            if (customer?.extraAttributes?.length) {
                updateData.extra_attributes = customer.extraAttributes.reduce<{ [key: number]: { attribute_id: number; value: string; }}>((acc, attr) => {
                    acc[attr.id] = {
                        attribute_id: attr.id,
                        value: data[`extra_attribute_${attr.id}`] || '',
                    };
                    return acc;
                }, {});
            }
        }

        customer && update({
            customerId: customer?.id,
            data: updateData,
        })
            .then((res) => {
                toastClear().then(() => {
                    showSuccess({message: res.message || 'Customer updated successfully!', duration: 2000}).then(() => {
                        history.goBack();
                    });
                })
            })
            .catch((res) => {
                if (!errorMessage) {
                    if (res) {
                        showError({message: res});
                    } else {
                        showError({message: 'Could not update customer.'});
                    }
                }
            })
            .finally(() => {});
    };

    const onBackClicked = async () => {
        Promise.all([
            formClearErrors(),
            customerClearErrors()
        ]).finally(() => history.goBack())
    }

    return (
        <CustomerPage
            customer={customer}
            customerForm={customerForm}
            customerFormFieldInputs={customerFormFieldInputs}
            onSelectProfile={onSelectProfile}
            onSubmitCustomer={onSubmitCustomer}
            onBackClicked={onBackClicked}
            isFetching={isFetching}
            isLoading={isFetching}
        />
    );
};
