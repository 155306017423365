import {createAction, createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {chatsService, getAllChatsResponse} from "../../services";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";
import {ChatMessage} from "../../models";
import {Ages} from "../../utils/ages";

export const getAllAction = createAsyncThunk<getAllChatsResponse, { customerId?: number }>(
    `${type}/getAllAction`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId} = args;
            return {
                ...(await chatsService.getAll(customerId)),
                customerId,
            };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addReceivedMessageAction = createAction<ChatMessage>(`${type}/addReceivedMessage`);

export const addOptimisticMessageAction = createAction<ChatMessage>(`${type}/addOptimisticMessage`);
export const updateMessageAction = createAction<{ localId: string; chatMessage: ChatMessage }>(`${type}/updateMessage`);


let tempIdCounter = -1;
const getTempId = () => tempIdCounter--;

export const sendMessageAction = createAsyncThunk<
    { customerId: number; message: ChatMessage; localId: string },
    { customerId: number; message: string; localId: string }
>(
    `${type}/sendMessageAction`,
    async (args, { dispatch, rejectWithValue }) => {
        const { customerId, message, localId } = args;
        try {
            // Create an optimistic message and add it immediately
            const optimisticMessage: ChatMessage = {
                id: getTempId(), // temporary id
                localId,
                customerId,
                sender: "app",
                sentAtTs: (new Ages()).toTs(),
                message,
                pending: true,
            };

            dispatch(addOptimisticMessageAction(optimisticMessage));
            // Call the API to send the message
            const response = await chatsService.sendMessage(customerId, message, localId);

            // Ensure response contains valid data before proceeding
            if (! response.results || !response.results.chatMessage || !response.results.localId) {
                console.error("Invalid response from server:", response);
                throw new Error("Failed to get valid chat message from server.");
            }

            const { localId: responseLocalId, chatMessage, replyMessage } = response.results;
            // Dispatch an update action to replace the optimistic message using the localId
            dispatch(updateMessageAction({ localId: responseLocalId, chatMessage: chatMessage }));

            // If an auto-reply was returned, add it to the store
            if (replyMessage && replyMessage.id) {
                dispatch(addReceivedMessageAction(replyMessage));
            }

            return { customerId, message: chatMessage, localId: responseLocalId };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);



export const setSelectedIdAction = createAsyncThunk(
    `${type}/setSelectedIdAction`,
    async (args: {id?: number}) => {
        const result = await chatsService.setSelectedId(args);
        return {
            selectedId: result.id,
        };
    }
);


export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

