import {createAsyncThunk} from '@reduxjs/toolkit';

import {customersService, getByIdResponse, CustomerUpdateData} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";
import {ApiResponse} from "../../models";

export const getAllCustomers = createAsyncThunk(
    `${type}/getAllCustomers`,
    async (_, {rejectWithValue}) => {
        try {
            return await customersService.getAll();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCustomerById = createAsyncThunk<getByIdResponse, number>(
    `${type}/getCustomerById`,
    async (id: number, {rejectWithValue}) => {
        try {
            return await customersService.getById(id);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setSelectedIdCustomer = createAsyncThunk(
    `${type}/setSelectedIdCustomer`,
    async (arg: { id: number }) => {
        const {id} = arg;
        const result = await customersService.setSelectedId(id);
        return {
            selectedId: result.id,
        };
    }
);

export const setSelectedCustomerProfileId = createAsyncThunk(
    `${type}/setSelectedCustomerProfileId`,
    async (arg: { profileId: number }) => {
        const {profileId} = arg;
        const result = await customersService.setSelectedProfileId(profileId);
        return {
            selectedProfileId: result.profileId,
        };
    }
);


export const updateAction = createAsyncThunk<ApiResponse, { customerId: number, data: CustomerUpdateData }>(
    `${type}/updateAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const {customerId, data} = arg;
            return await customersService.update(customerId, data);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);
