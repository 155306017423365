import {ApiResponse} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface ProfileUpdateData {
    first_name: string;
    last_name: string;
    // gender: number;
    // dateOfBirth?: string | null;
    // allowSocialMediaSharing: boolean;
}

class ProfilesService {
    async update(profileId: number, data: ProfileUpdateData): Promise<ApiResponse> {
        return await apiClient
            .post(`${this.baseUrl}/${profileId}`, data)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to update profile")
            );
    }

    async setSelectedId(selectedId: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: selectedId});
            })
        }
        return await fakePromise();
    }


    constructor(private readonly baseUrl: string) {
    }
}

export const profilesService = new ProfilesService(
    '/profiles'
);
