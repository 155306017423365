import {
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonToolbar,
} from '@ionic/react';

import React, {MenuHTMLAttributes, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useAuthStore} from '../../redux/auth';

import {
    fileTrayFull,
    fileTrayFullOutline,
    folder,
    folderOutline,
    home,
    homeOutline,
    informationCircle,
    informationCircleOutline,
    logOut,
    logOutOutline,
    notifications,
    notificationsOutline,
    shieldCheckmark,
    shieldCheckmarkOutline,
    swapHorizontal,
    swapHorizontalOutline,
    warningOutline,
    warningSharp,
    settings,
    settingsOutline,
    chatbubbles,
    chatbubblesOutline,
} from 'ionicons/icons';


import './Menu.css';
import {useCustomerStore} from "../../redux/customers";
import {useModalPageStore} from "../../redux/modal-pages";
import {useUserStore} from "../../redux/users";

interface AppPage {
    url?: string;
    routerLink?: string;
    onClick?: any;
    href?: any;
    visible?: boolean;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

interface InfoPage {
    url?: string;
    setIsOpen: (isOpen: boolean) => void;
    visible?: boolean;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

interface MenuProps {
    setMustLogout: (newMustLogout: boolean) => void
}

const Menu: React.FC<MenuProps> = (props) => {
    const menuRef = useRef<MenuHTMLAttributes<any>>();
    const location = useLocation();
    const history = useHistory();
    const authStore = useAuthStore();
    const {userName, isAuthed} = authStore;
    const userStore = useUserStore();
    const {setIsOpenDeleteModal} = userStore;
    const customerStore = useCustomerStore();
    const {customersCount} = customerStore;
    const hasMultipleCustomers = (customersCount > 1);
    const {activeCustomer} = customerStore;
    const {setMustLogout} = props;

    const modalPageStore = useModalPageStore();
    const {setIsOpenAbout, setIsOpenPrivacy, setIsOpenTermsOfUse} = modalPageStore;

    const infoPages: InfoPage[] = [
        {
            title: 'Delete Account',
            setIsOpen: setIsOpenDeleteModal,
            iosIcon: warningOutline,
            mdIcon: warningSharp
        },
        {
            title: 'App info',
            setIsOpen: setIsOpenAbout,
            iosIcon: informationCircleOutline,
            mdIcon: informationCircle
        },
        {
            title: 'Privacy Policy',
            setIsOpen: setIsOpenPrivacy,
            iosIcon: shieldCheckmarkOutline,
            mdIcon: shieldCheckmark
        },
        {
            title: 'Terms of Use',
            setIsOpen: setIsOpenTermsOfUse,
            iosIcon: folderOutline,
            mdIcon: folder
        },
    ]

    const appPages: AppPage[] = [
        {
            title: 'Home',
            routerLink: '/home',
            iosIcon: homeOutline,
            mdIcon: home,
            visible: isAuthed && !!activeCustomer
        },
        {
            title: 'Account',
            routerLink: '/account',
            iosIcon: fileTrayFullOutline,
            mdIcon: fileTrayFull,
            visible: isAuthed && !!activeCustomer
        },
        {
            title: 'Chat',
            routerLink: '/chat',
            iosIcon: chatbubblesOutline,
            mdIcon: chatbubbles,
            visible: isAuthed && !!activeCustomer && !!activeCustomer?.company.featuresChat
        },
        {
            title: 'Communications',
            routerLink: '/notifications',
            iosIcon: notificationsOutline,
            mdIcon: notifications,
            visible: isAuthed && !!activeCustomer
        },
        {
            title: 'Switch Account',
            routerLink: '/customer-select',
            iosIcon: swapHorizontalOutline,
            mdIcon: swapHorizontal,
            visible: isAuthed && !!hasMultipleCustomers,
        },

        {
            title: 'Settings',
            routerLink: '/settings',
            iosIcon: settingsOutline,
            mdIcon: settings,
            visible: isAuthed && !!activeCustomer && !!activeCustomer?.company.featuresSelfManage
        },
        {
            title: 'Log Out',
            onClick: () => {
                setMustLogout(true);
            },
            iosIcon: logOutOutline,
            mdIcon: logOut,
            visible: isAuthed
        }
    ];

    return (
        <IonMenu contentId="main" side="start" type="reveal" ref={() => menuRef}>
            <IonContent>
                <IonList>
                    <IonListHeader>{(activeCustomer?.company && activeCustomer.company.name) || 'swool.io'}</IonListHeader>
                    <IonMenuToggle>
                        <IonNote onClick={() => {
                            if (isAuthed) {
                                history.push('/auth/profile');
                            }
                        }}
                        >
                            {userName}
                        </IonNote>
                    </IonMenuToggle>
                    {appPages.map((appPage, index) => {
                        return (
                            appPage.visible !== false &&
                            (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem button className={location.pathname === appPage.url ? 'selected' : ''}
                                             href={appPage.href}
                                             routerLink={appPage.routerLink} onClick={appPage.onClick}
                                             routerDirection="none" lines="none" detail={false}
                                    >
                                        <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon}/>
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            )
                        );
                    })}
                </IonList>
            </IonContent>
            <IonFooter>
                <IonToolbar className="menu-footer-toolbar">
                    <IonButtons slot={"start"} className={''}>
                        {infoPages.map((infoPage, index) => {
                            return (
                                infoPage.visible !== false &&
                                (
                                    <IonMenuToggle key={index} autoHide={false}>
                                        <IonButton
                                            className={''}
                                            color={"medium"}
                                            size={"small"}
                                            onClick={() => infoPage.setIsOpen(true)}
                                        >
                                            <div className="menu-footer-button-inner">
                                                <IonIcon
                                                    size={"small"}
                                                    slot="start"
                                                    ios={infoPage.iosIcon}
                                                    md={infoPage.mdIcon}
                                                    style={{marginBottom: "-4px"}}
                                                />
                                                <IonLabel className="menu-footer-button-inner">{infoPage.title}</IonLabel>
                                            </div>
                                        </IonButton>
                                    </IonMenuToggle>
                                )
                            );
                        })}
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </IonMenu>
    );
};

export default Menu;



