import { useEffect, useRef } from 'react';

export const useScrollToBottom = (dependency: any[]) => {
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const initialRender = useRef(true);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: initialRender.current ? "auto" : "smooth",
            });
            // After the first render, set the flag to false
            initialRender.current = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependency);

    return messagesEndRef;
};
