import {createSelector} from '@reduxjs/toolkit';

import {SystemState, type} from './state';

const featureStateSelector = (state: { [type]: SystemState }) =>
    state[type];


export const isOnlineSelector = createSelector(
    featureStateSelector,
    state => state.isOnline
);

export const enableAnimationSelector = createSelector(
    featureStateSelector,
    state => state.enableAnimation
);
