import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';

import {HomePage} from '../components';
import {useProfilePackageStore} from "../../../redux/profile-packages";
import {useCustomerStore} from "../../../redux/customers";
import {useBookingsStore} from "../../../redux/bookings";
import {useToastStore} from "../../../redux/toast";
import {useHistory} from "react-router";
import {useAuthStore} from "../../../redux/auth";
import {Booking} from "../../../models";

type Props = {
};

export const HomePageContainer: React.FC<Props> = props => {
    const refresherRef = useRef<HTMLIonRefresherElement>(null);
    const history = useHistory();
    const toastStore = useToastStore();
    const authStore = useAuthStore();
    const customerStore = useCustomerStore();
    const bookingsStore = useBookingsStore();
    const profilePackageStore = useProfilePackageStore();
    const {authStatus} = authStore;
    const [selectedBooking, setSelectedBooking] = useState<Booking>();

    const {
        errorMessage: profilePackageErrorMessage,
        clearErrors: profilePackageClearErrors,
    } = profilePackageStore;

    const {
        errorMessage: bookingsErrorMessage,
        clearErrors: bookingsClearErrors,
    } = bookingsStore;

    const {
        isFetching: customerIsFetching,
        selectedId: selectedCustomerId,
        errorMessage: customerErrorMessage,
        clearErrors: customerClearErrors,
        activeCustomer,
        customersCount,
        getById,
        getAll,
    } = customerStore;

    const canEditProfile = !!(!customerIsFetching && activeCustomer?.company?.featuresSelfManage);

    const completeRefresh = useCallback(() => {
        if (refresherRef.current) {
            refresherRef.current.complete();
        }
    }, [refresherRef]);

    const fetchRelatedData = useCallback((customerId: number) => {
        Promise.all([
            bookingsStore.getAll({customerId}),
            profilePackageStore.getAll({customerId}),
        ])
            .catch(() => { /** Handled by state and toast */ })
            .finally(completeRefresh)
    }, [activeCustomer, bookingsStore, toastStore, profilePackageStore])

    const fetchCustomerData = useCallback(async () => {
        if (!authStatus || !selectedCustomerId || typeof selectedCustomerId !== "number") {
            return;
        }

        try {
            if (customersCount && customersCount > 1) {
                await getById(selectedCustomerId);
            } else {
                await getAll();
            }

            fetchRelatedData(selectedCustomerId);
        } catch (error) {
            completeRefresh();
            if (typeof error === 'object' && error !== null) {
                const apiError = error as { status?: number };
                if (apiError.status === 404) {
                    history.replace('/customer-select');
                }
            }
        }
    }, [getById, getAll, selectedCustomerId, customersCount]);

    useEffect(() => {
        fetchCustomerData();
        completeRefresh();
    }, [])

    useEffect(() => {
        if (authStatus && selectedCustomerId && typeof selectedCustomerId === "number"){
            fetchRelatedData(selectedCustomerId);
        }
    }, [selectedCustomerId])

    useEffect(() => {
        bookingsErrorMessage && toastStore.showError({message: bookingsErrorMessage}).then(() => {
            bookingsClearErrors();
        });
    }, [bookingsErrorMessage])

    useEffect(() => {
        profilePackageErrorMessage && toastStore.showError({message: profilePackageErrorMessage}).then(() => {
            profilePackageClearErrors();
        });
    }, [profilePackageErrorMessage])

    useEffect(() => {
        customerErrorMessage && toastStore.showError({message: customerErrorMessage}).then(() => {
            customerClearErrors();
        });
    }, [customerErrorMessage])

    const handleRefresh = async (event: CustomEvent) => {
        if (!customerIsFetching){
            fetchCustomerData();
        }
    }

    const handleEditProfile = async (profileId: number, event: React.MouseEvent<HTMLIonCardTitleElement, MouseEvent>) => {
        if (!customerIsFetching){
            history.push(`/profile/${profileId}`);
        }
    }

    return <HomePage
        customer={activeCustomer}
        doRefresh={handleRefresh}
        refresherRef={refresherRef}

        canEditProfile={canEditProfile}
        selectedBooking={selectedBooking}
        setSelectedBooking={setSelectedBooking}

        onEditProfileClicked={handleEditProfile}
    />;
};
