import React, {useEffect, useState} from 'react';
import {PushNotifications} from '@capacitor/push-notifications';
import {FCM} from '@capacitor-community/fcm';
import {Device} from '@capacitor/device';
import {Capacitor} from "@capacitor/core";
import {useAuthStore} from "../../redux/auth";
import {useToastStore} from "../../redux/toast";
import {useCustomerStore} from "../../redux/customers";
import {useHistory} from "react-router";
import {useChatsStore} from "../../redux/chats";
import {handleNotificationAction} from "../common/pushListenerHandler";

interface PushListenerProps {
}


const PushListener: React.FC = () => {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    const isDeviceAvailable = Capacitor.isPluginAvailable('Device');
    const history = useHistory();
    const {showError} = useToastStore();
    const {registerFcmToken} = useAuthStore();
    const [deviceUuid, setDeviceUuid] = useState<string>();
    const [devicePlatform, setDevicePlatform] = useState<string>();
    const [deviceFcmToken, setDeviceFcmToken] = useState<string>();
    const [registered, setRegistered] = useState<boolean>(false);

    const customerStore = useCustomerStore();
    const {setSelectedId: setSelectedCustomerId, customers} = customerStore;
    const chatsStore = useChatsStore();
    const {addMessage} = chatsStore;

    const addListeners = async () => {
        await PushNotifications.addListener('registrationError', (err) => {
            console.error('Registration error:', err);
            showError({message: 'Error while registering notifications', duration: 2000});
        });

        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received:', notification);
            if (!notification?.data?.type) {
                console.warn('Push notification received without a type key:', notification.data);
                return;
            }
            const {type} = notification.data;
            try {
                switch (type) {
                    case 'chatMessage': {
                        const {chatMessage} = notification.data;
                        if (chatMessage) {
                            const parsedMessage = JSON.parse(chatMessage);
                            console.log('Parsed ChatMessage:', parsedMessage);
                            if (parsedMessage.customerId) {
                                handleIncomingMessage(parsedMessage);
                            }
                        }
                        break;
                    }
                    default:
                        console.warn('Unknown notification type received:', type);
                }
            } catch (error) {
                console.error('Failed to process push notification:', error, notification.data);
            }
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', async (notificationAction) => {
            const data = notificationAction?.notification?.data;
            if (!data) return;
            const currentLocation = history?.location?.pathname;
            await handleNotificationAction({
                data,
                customers,
                setSelectedCustomerId,
                history,
                currentLocation,
                addMessage,
            });
        });
    };

    const handleIncomingMessage = (chatMessage: any) => {
        console.log(`New message received for customer ${chatMessage.customerId}:`, chatMessage);
        if (customerStore.activeCustomer?.id === chatMessage.customerId) {
            addMessage(chatMessage);
        } else {
            console.warn('Message received but customer is not active. Skipping auto-update.');
        }
    };

    const registerNotifications = async () => {
        if (isPushNotificationsAvailable) {
            let permStatus = await PushNotifications.checkPermissions();
            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }
            if (permStatus.receive === 'granted') {
                await PushNotifications.register();
                try {
                    const {token} = await FCM.getToken();
                    setDeviceFcmToken(token);
                } catch (error) {
                    console.error('Error getting FCM token:', error);
                    showError({message: 'Error while registering notifications', duration: 2000});
                }
            }
        }
    };

    useEffect(() => {
        if (!registered && isPushNotificationsAvailable && isDeviceAvailable) {
            Promise.all([Device.getInfo(), Device.getId(), addListeners()])
                .then(([deviceInfo, deviceId]) => {
                    setDevicePlatform(deviceInfo.platform);
                    setDeviceUuid(deviceId.identifier);
                    if (deviceInfo.platform === 'ios' || deviceInfo.platform === 'android') {
                        registerNotifications().catch((err) => showError({
                            message: 'Error while registering notifications',
                            duration: 2000
                        }));
                    }
                })
                .catch((err) => showError({message: 'Error while registering notifications', duration: 2000}));
        }
    }, [isPushNotificationsAvailable, isDeviceAvailable]);

    useEffect(() => {
        if (!registered && deviceFcmToken && devicePlatform && deviceUuid) {
            registerFcmToken({deviceUuid, fcmToken: deviceFcmToken, platform: devicePlatform}).then(() => {
                setRegistered(true);
            });
        }
    }, [deviceFcmToken, devicePlatform, deviceUuid]);

    return null;
};

export default PushListener;
