import React, {useEffect, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {useChatsStore} from '../../redux/chats';
import {useAuthStore} from '../../redux/auth';
import {v4 as uuidv4} from 'uuid';
import {handleNotificationAction} from "../common/pushListenerHandler";
import {useCustomerStore} from "../../redux/customers";

const WebPushListener: React.FC = () => {
    const { addMessage } = useChatsStore();
    const { registerFcmToken } = useAuthStore();

    const customerStore = useCustomerStore();
    const {setSelectedId: setSelectedCustomerId, customers} = customerStore;

    const [firebaseConfig, setFirebaseConfig] = useState<any>(null);
    const [messaging, setMessaging] = useState<any>(null);
    const [deviceUuid, setDeviceUuid] = useState<string | null>(null);

    // Persist or generate UUID for this browser session
    useEffect(() => {
        const storedUuid = localStorage.getItem('device_uuid');
        if (storedUuid) {
            setDeviceUuid(storedUuid);
        } else {
            const newUuid = uuidv4();
            localStorage.setItem('device_uuid', newUuid);
            setDeviceUuid(newUuid);
        }

        fetch('/web-config.json')
            .then((res) => res.json())
            .then((config) => {
                if (!config?.firebase) {
                    throw new Error('Missing firebase config in web-config.json');
                }
                setFirebaseConfig(config.firebase);
                const firebaseApp = initializeApp(config.firebase);
                const messagingInstance = getMessaging(firebaseApp);
                setMessaging(messagingInstance);
            })
            .catch((error) => {
                console.error('Error loading web-config.json:', error);
            });
    }, []);

    // Request permission and set up token and foreground messaging
    useEffect(() => {
        if (!firebaseConfig || !messaging || !deviceUuid) return;

        const requestPermissionAndToken = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const swRegistration = await navigator.serviceWorker.ready;
                    const token = await getToken(messaging, {
                        vapidKey: firebaseConfig.vapidKey,
                        serviceWorkerRegistration: swRegistration,
                    });
                    console.log('Web Push Token:', token);
                    if (token) {
                        await registerFcmToken({ deviceUuid, fcmToken: token, platform: 'web' });
                    } else {
                        console.warn('No web push token was retrieved.');
                    }
                } else {
                    console.warn('Notification permission not granted.');
                }
            } catch (error) {
                console.error('Error requesting web push permission:', error);
            }
        };

        requestPermissionAndToken();

        // Foreground push messages
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Foreground web push message:', payload);
            // Optionally, if you want to update the store directly:
            if (payload.data?.type === 'chatMessage') {
                try {
                    const parsedMessage = JSON.parse(payload.data.chatMessage);
                    console.log('Parsed ChatMessage:', parsedMessage);
                    if (parsedMessage?.customerId) {
                        addMessage(parsedMessage);
                    }
                } catch (err) {
                    console.error('Error processing web push message:', err);
                }
            } else {
                console.warn('Unknown notification type:', payload.data?.type);
            }
        });

        return () => unsubscribe();
    }, [firebaseConfig, messaging, deviceUuid, registerFcmToken, addMessage]);

    // Listen for messages forwarded from the service worker and handle actions
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            const messageHandler = async (event: MessageEvent) => {
                const payload = event.data;
                console.log('Received message from SW:', payload);
                if (payload?.data && payload.data.type) {
                    // Here we delegate handling to our abstracted function.
                    // For the web version, you might not have a customer list or navigation logic,
                    // so you can pass only what you need.
                    const currentLocation = window?.location?.pathname;
                    await handleNotificationAction({
                        data: payload.data,
                        customers: customers,
                        setSelectedCustomerId: setSelectedCustomerId,
                        history: { push: (url: string) => window.location.assign(url) },
                        currentLocation,
                        addMessage,
                    });
                }
            };

            navigator.serviceWorker.addEventListener('message', messageHandler);

            return () => {
                navigator.serviceWorker.removeEventListener('message', messageHandler);
            };
        }
    }, [addMessage]);

    return null;
};

export default WebPushListener;
