import * as React from 'react';

import {SettingsPage} from '../components';
import {useCustomerStore} from "../../../redux/customers";

type Props = {
};

export const SettingsPageContainer: React.FC<Props> = props => {
    const customerStore = useCustomerStore();

    const {
        isFetching: customerIsFetching,
        activeCustomer,
    } = customerStore;

    const canEdit = !!(!customerIsFetching && activeCustomer?.company?.featuresSelfManage);

    return <SettingsPage
        customer={activeCustomer}
        canEditProfile={canEdit}
        canEditContact={canEdit}
    />;
};
