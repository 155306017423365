import {ApiResponse, ChatMessage, KeyedChatMessages} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface getAllChatsResponse extends ApiResponse {
    results: { messages: KeyedChatMessages };
    customerId?: number;
}

export interface sendMessageResponse extends ApiResponse {
    results: { localId: string, chatMessage: ChatMessage, replyMessage?: ChatMessage };
    message?: string;
}

class ChatsService {
    async getAll(customerId?: number): Promise<getAllChatsResponse> {

        const url = `${this.baseUrl}` + (!!customerId ? `?customer_id=${customerId}` : '' )
        return await apiClient
            .get(url)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve chat data.")
            )
    }

    async sendMessage(customerId: number, message: string, localId: string): Promise<sendMessageResponse> {
        const url = `${this.baseUrl}/send`;

        return await apiClient
            .post(url, {
                customer_id: customerId,
                message,
                local_id: localId, // localId is only for client-side matching
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to send chat message.")
            );
    }

    async setSelectedId(args: {id?: number}): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: args.id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    constructor(private readonly baseUrl: string) { }
}

export const chatsService = new ChatsService(
    '/chats'
);
