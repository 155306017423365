import {createSelector} from '@reduxjs/toolkit';

import {adapter, ProfileState, type} from './state';

const {selectEntities} = adapter.getSelectors();


const featureStateSelector = (state: { [type]: ProfileState }) =>
    state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedLearnerId
);

export const isFetchingSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const isLoadingSelector = createSelector(
    featureStateSelector,
    state => state.isLoading
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);
