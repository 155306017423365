import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';

import {Ages, tsToDate, tsToDayOfWeekNum} from '../../../utils/ages'
import {AvailableDateOption, SlotsPage} from '../components';
import {useAppSelector} from "../../../redux/hooks";
import {profileByIdSelector, useCustomerStore} from "../../../redux/customers";
import {bookingsByProfilePackageIdSelector, useBookingsStore} from "../../../redux/bookings";
import {profilePackageByIdSelector} from "../../../redux/profile-packages";
import {slotsByDayOfWeekSelector, useSlotsStore} from "../../../redux/slots";
import {useToastStore} from "../../../redux/toast";
import {Employee, Facility, LessonType, Slot} from "../../../models";
import {useIonAlert} from "@ionic/react";
import {useHolidaysStore} from "../../../redux/holidays";
import {useWaitingBookingsStore} from "../../../redux/waiting-bookings";

type PackageDetailsPageContainerProps = {
    profilePackageId?: number
};

export const SlotsPageContainer: React.FC<PackageDetailsPageContainerProps> = props => {
    // Page Props (Url)
    const {profilePackageId} = props;

    // Date Filters
    const [selectedDate, setSelectedDate] = useState<number>(-1);
    const [selectedDateIsToday, setSelectedDateIsToday] = useState<boolean>(false);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState<number>(-99);

    // Triggers & UI
    // const refresherRef = useRef<HTMLIonRefresherElement>(null);
    const [availableDates, setAvailableDates] = useState<AvailableDateOption[]>([]);
    const [availableDatesChanged, setAvailableDatesChanged] = useState<boolean>(false);
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState<boolean>(false);
    const [isSlotModalOpen, setIsSlotModalOpen] = useState<boolean>(false);
    const [selectedSlot, setSelectedSlot] = useState<Slot>();
    // Direct Selectors
    const profilePackage = useAppSelector((state) => profilePackageByIdSelector(state, profilePackageId));
    const visibleSlots = useAppSelector((state) => slotsByDayOfWeekSelector(state, tsToDayOfWeekNum(selectedDate) ));
    const bookings = useAppSelector((state) => bookingsByProfilePackageIdSelector(state, profilePackageId));
    const profile = useAppSelector((state) => profileByIdSelector(state, profilePackage?.learnerId));
    // Store & Action Selectors
    const toastStore = useToastStore();
    const customerStore = useCustomerStore();
    const {activeCompany: company} = customerStore;
    const slotsStore = useSlotsStore();
    const {isFetching, slotsBySelectedDrawKey: slots, existingDrawKeys, setSelectedDraw, clearProfilePackageDraws, getAllSlotsWeekly, errorMessage: slotsErrorMessage, clearErrors: slotClearErrors} = slotsStore;
    const holidaysStore = useHolidaysStore();
    const {holidaysByActiveCustomer: holidays} = holidaysStore;
    const bookingsStore = useBookingsStore();
    const {isChangingBooking, bookingCreate, bookingDelete, bookingCancel, errorMessage: bookingsErrorMessage, clearErrors: bookingsClearErrors } = bookingsStore;
    const waitingBookingsStore = useWaitingBookingsStore();
    const {waitingBookingCreate, waitingBookingDelete} = waitingBookingsStore;
    const [presentConfirmAlert] = useIonAlert();

    // Config
    const weeksAvailablePast = profilePackage?.weeksAvailablePast || 1;
    const weeksAvailableFuture = profilePackage?.weeksAvailableFuture || 3;


    // Filters
    const [isSlotFilterModalOpen, setIsSlotFilterModalOpen] = useState<boolean>(false);
    const [filterBookedOnly, setFilterBookedOnly] = useState<boolean>(false);
    const [filterAvailableOnly, setFilterAvailableOnly] = useState<boolean>(false);
    const [filterSlotType, setFilterSlotType] = useState<number>();
    const [filterFacility, setFilterFacility] = useState<number>();
    const [filterEmployee, setFilterEmployee] = useState<number>();

    // Prepare facility filters
    const facilities = visibleSlots ? visibleSlots.reduce((acc: Facility[], slot) => {
        if(slot.facilityId && !acc.find(item => item.id === slot.facilityId && slot.facility)) {
            acc.push(slot.facility);
        }
        return acc;
    }, []).sort((a, b) => a.name.localeCompare(b.name))  : [];
    // Prepare employee filters
    const employees = visibleSlots ? visibleSlots.reduce((acc: Employee[], slot) => {
        if(slot.employees) {
            slot.employees.forEach((employee) => {
                if (!acc.find(e => e.id === employee.id)) {
                    acc.push(employee);
                }
            });
        }
        return acc;
    }, []).sort((a, b) => a.fullName.localeCompare(b.fullName))  : [];
    // Prepare slot type filters
    const slotTypes = visibleSlots ? visibleSlots.reduce((acc: LessonType[], slot) => {
        if(slot.lessonType && !acc.find(item => slot.lessonType && item.id === slot.lessonType.id)) {
            acc.push(slot.lessonType);
        }
        return acc;
    }, []).sort((a, b) => a.name.localeCompare(b.name))  : [];

    const filteredSlots = visibleSlots ? visibleSlots.filter(slot =>
        // Filter "My Bookings" selection
        (!filterBookedOnly || slot.isBooked)
        // Filter availability
        &&  (!filterAvailableOnly || slot.canBook || slot.canCancel || slot.canCancelWait || slot.isBooked || slot.isWaitingBooked)
        // Filter Lesson Type selection
        &&  (!filterSlotType || slot.lessonType?.id === filterSlotType)
        // Filter Facility selection
        &&  (!filterFacility || slot.facilityId === filterFacility)
        // Filter Employee selection
        &&  (!filterEmployee || slot.employees?.find(e => e.id === filterEmployee))
    ).sort((a, b) => a.startTimeTs - b.startTimeTs) : [];


    useEffect(() => {
        slotClearErrors();
        bookingsClearErrors();
    }, []);

    useEffect(() => {
        if (availableDatesChanged){
            setTimeout(() => {
                setAvailableDatesChanged(false);
            }, 100)
            updateAvailableDates();
        }
    }, [availableDatesChanged]);

    useEffect(() => {
        if (profilePackageId && profilePackage) {
            setSelectedWeekIndex(0);
        }
    }, [profilePackageId])

    useEffect(() => {
        setSelectedDraw({profilePackageId, startDate: getSelectedWeekStartDate().toTs()}).finally(() => {
            isInitialLoadComplete && setAvailableDatesChanged(true);
        })
    }, [profilePackageId, selectedDate])

    useEffect(() => {
        if(selectedDate && selectedDate > 0 && selectedWeekIndex > -99){
            setSelectedDateIsToday((new Ages(selectedDate).equalsDay(new Ages())))
        }
    }, [selectedDate]);

    useEffect(() => {
        if (profilePackage && selectedWeekIndex > -99) {
            fetchSlots(false, !isInitialLoadComplete, !isInitialLoadComplete);
        }
    }, [selectedWeekIndex]);

    useEffect(() => {
        slotsErrorMessage && toastStore.showError({message: slotsErrorMessage}).then(() => {
            slotClearErrors();
        });
    }, [slotsErrorMessage])

    useEffect(() => {
        bookingsErrorMessage && toastStore.showError({message: bookingsErrorMessage}).then(() => {
            bookingsClearErrors();
        });
    }, [bookingsErrorMessage])

    const getSelectedWeekStartDate = () => {
        const todayDate = new Ages();
        return todayDate.subDays(todayDate.date.getUTCDay() !== 0 ? 0 : 1).startOfWeek().startOfDay().addWeeks(selectedWeekIndex); // Add the selected week index
    }

    /**
     * Determine and return the best day of the week for initial selection
     * after a new week is loaded
     * @param startWeekDate
     * @param slots
     */
    const getInitialSelectedDate = (startWeekDate: Ages, slots: Slot[]) => {
        const todayDate = (new Ages()).startOfDay();
        const endWeekDate = startWeekDate.copy().endOfWeek();
        const todayInSelectedWeek = startWeekDate.lte(todayDate) && endWeekDate.gte(todayDate);
        let selectedDate = todayInSelectedWeek ? todayDate : startWeekDate;
        const selectedDow = selectedDate.date.getUTCDay();
        const bookedDows = Object.entries(slots).filter(([id, slot]) => slot.isBooked).map(([id, slot]) => slot.dow)
            .filter((d, i, lds) => lds.indexOf(d) === i);
        const waitingBookedDows = Object.entries(slots).filter(([id, slot]) => slot.isWaitingBooked).map(([id, slot]) => slot.dow)
            .filter((d, i, lds) => lds.indexOf(d) === i); // Unique
        // If today is not selected, select the earliest
        // Day of week, where there is a booking
        if (! todayInSelectedWeek) {
            if (bookedDows.some(bookedDow => (bookedDow > selectedDow))) {
                let iProtect = 0;
                const earliestBookedDow = Math.min(...bookedDows.filter(bookingDow => (bookingDow >= selectedDow)));
                while (iProtect < 7 && (selectedDate.date.getUTCDay() < earliestBookedDow)) {
                    selectedDate = selectedDate.copy().addDays(1);
                    iProtect++;
                }
            }
            else if (waitingBookedDows.some(bookedDow => (bookedDow > selectedDow))) {
                let iProtect = 0;
                const earliestBookedDow = Math.min(...waitingBookedDows.filter(bookingDow => (bookingDow >= selectedDow)));
                while (iProtect < 7 && (selectedDate.date.getUTCDay() < earliestBookedDow)) {
                    selectedDate = selectedDate.copy().addDays(1);
                    iProtect++;
                }
            }
        }

        return selectedDate;
    }

    /**
     * Fetch and load the slots available for the profile package
     * Optionally fetch fresh booking and waiting booking records after the slots are loaded
     * @param clearDraw
     * @param fetchBookings
     * @param fetchWaitingBookings
     */
    const fetchSlots = (clearDraw: boolean = false, fetchBookings: boolean = false, fetchWaitingBookings: boolean = false) => {
        if (profilePackage){
            const startWeekDate = getSelectedWeekStartDate();
            const _afterFetchSlots = () => {
                setSelectedDraw({profilePackageId: profilePackage.id, startDate: startWeekDate.toTs()}).then(() => {
                    setSelectedDate(getInitialSelectedDate(startWeekDate, visibleSlots).toTs());
                    setAvailableDatesChanged(true);
                });
            }
            const _fetchSlots = () => {
                Promise.all([
                    fetchBookings && bookingsStore.getAll({learnerId: profilePackage.learnerId}),
                    !!profilePackage.useWaiting && fetchWaitingBookings && waitingBookingsStore.getAll({learnerId: profilePackage.learnerId}),
                    getAllSlotsWeekly({profilePackageId: profilePackage.id, startDate: startWeekDate.toTs()}),
                ])
                    .then(([bookingsResponse, waitingBookingsResponse, slotsResponse]) => {
                        if (slotsResponse?.results?.slots) {
                            // const {slots} = slotsResponse.results;
                            // If the selected date is in the selected period, then leave it
                            if (selectedDate < startWeekDate.toTs() || selectedDate > startWeekDate.copy().endOfWeek().toTs()) {
                                _afterFetchSlots();
                            }
                            else {
                                setAvailableDatesChanged(true);
                            }
                        }
                    })
                    .catch(() => { /** handled by state and toasts */
                    })
                    .finally(() => {
                        setIsInitialLoadComplete(true);
                    });
            }

            // If we need to refresh the bookings or waiting bookings, then the
            // draws are no longer valid, and we need fresh slots data
            if (clearDraw || fetchBookings || fetchWaitingBookings){
                clearProfilePackageDraws({profilePackageId: profilePackage.id}).finally(() => {
                    _fetchSlots()
                });
            }
            else {
                // If we have gotten the data before and there have been no changes,
                // Then we can just use the same data by setting the selectedDraw Key
                const drawKey = `${profilePackage.id}-${startWeekDate.toTs()}`;
                if(existingDrawKeys.includes(drawKey)){
                    _afterFetchSlots();
                }
                else {
                    _fetchSlots()
                }

            }
        }
    }

    /**
     * Set the dates available for selection
     * One week is loaded at a time. Determines whether the date has a booking
     */
    const updateAvailableDates = () => {
        setAvailableDates((availableDates) => {
            availableDates = [];
            for (let i = 0; i < 7; i++) {
                let isBooked: boolean = false;
                let isWaitingBooked: boolean = false;
                let isHoliday: boolean = false;
                const workingTimestamp = getSelectedWeekStartDate().addDays(i).toTs();

                if (slots) {
                    Object.entries(slots).every(([slotId, slot]) => {
                        if (slot.isBooked && `${slot.dow}` === `${tsToDayOfWeekNum(workingTimestamp)}`
                        ) {
                            isBooked = true;
                            return false;
                        }
                        return true;
                    });

                    Object.entries(slots).every(([slotId, slot]) => {
                        if (slot.isWaitingBooked && `${slot.dow}` === `${tsToDayOfWeekNum(workingTimestamp)}`
                        ) {
                            isWaitingBooked = true;
                            return false;
                        }
                        return true;
                    });
                }

                if (holidays) {
                    Object.entries(holidays).every(([holidayId, holiday]) => {
                        const endDate = new Ages(holiday.endDateTs).startOfDay(),
                            workingDate = new Ages(workingTimestamp).startOfDay();
                        let startDate = new Ages(holiday.startDateTs),
                            iProtect = 0;

                        if (workingDate.lt(startDate) || workingDate.gt(endDate)){
                            return true;
                        }

                        while(startDate.lte(endDate) && iProtect <= 8) {
                            if(startDate.date.getUTCDay() === i){
                                isHoliday = true;
                                return false;
                            }
                            startDate = startDate.addDays();
                            iProtect++;
                        }
                        return true;
                    });
                }

                availableDates.push({
                    active: !isHoliday && (!profilePackage?.isRecurring || isBooked),
                    ts: workingTimestamp,
                    date: tsToDate(workingTimestamp),
                    isHoliday: isHoliday,
                    isBooked: isBooked,
                    isWaitingBooked: isWaitingBooked,
                    isPast: workingTimestamp < selectedDate,
                });
            }

            availableDates = availableDates.sort((a, b) => (a.ts > b.ts ? 1 : -1));
            return availableDates;
        });
    }

    /**
     * Change the selected week */
    const handlePreviousWeek = () => {
        if (selectedWeekIndex > -weeksAvailablePast) {
            setSelectedWeekIndex((state) => state - 1)
        }
    }

    /**
     * Change the selected week */
    const handleNextWeek = () => {
        if (selectedWeekIndex < weeksAvailableFuture) {
            setSelectedWeekIndex((state) => state + 1)
        }
    }

    /**
     * Generic handling of booking changes. Slots must be reloaded to get their status
     * after a change has been made
     */
    const _handleBookingAction = useCallback((callback: Promise<any>, fetchBookings: boolean = true) => {
        callback.catch(() => { /** Handled by state and toast */ })
            .finally(() => {
                setIsSlotModalOpen(false);
                fetchSlots(true);
            })
    }, [fetchSlots, toastStore])

    /**
     * Generic handling of booking changes. Slots must be reloaded to get their status
     * after a change has been made
     */
    const _handleWaitingBookingAction = useCallback((callback: Promise<any>) => {
        callback.catch(() => { /** Handled by state and toast */ })
            .finally(() => {
                setIsSlotModalOpen(false);
                fetchSlots(false, false, true);
            })
    }, [fetchSlots, toastStore])

    /**
     * Make a new booking
     * @param slotId
     */
    const handleBookClicked = useCallback((slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        event && event.stopPropagation();
        presentConfirmAlert({
            header: "New Booking",
            subHeader: description,
            message: "Are you sure you want to make this booking?",
            buttons: [
                {
                    text: 'Nope',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (profilePackage) {
                            _handleBookingAction(bookingCreate({profilePackageId: profilePackage.id, slotId: slotId, startedTimestamp: selectedDate}))
                        } else {
                            toastStore.showError({message: "Failed to create booking. No package selected."});
                        }
                    },
                },
            ],
        })

    }, [bookingCreate, profilePackage, selectedDate, toastStore]);

    /**
     * Register on waiting list
     * @param slotId
     */
    const handleWaitClicked = useCallback((slotId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        event && event.stopPropagation();
        presentConfirmAlert({
            header: "Queue on Waiting list",
            subHeader: description,
            message: "Are you sure you want to add yourself to this waiting list?",
            buttons: [
                {
                    text: 'Nope',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (profilePackage) {
                            _handleWaitingBookingAction(waitingBookingCreate({profilePackageId: profilePackage.id, slotId: slotId, startedTimestamp: selectedDate}))
                        } else {
                            toastStore.showError({message: "Failed to add to waiting list. No package selected."});
                        }
                    },
                },
            ],
        })

    }, [waitingBookingCreate, profilePackage, selectedDate, toastStore]);

    /**
     * Delete an existing booking
     * @param bookingId
     */
    const handleCancelWaitClicked = useCallback((waitingBookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        event && event.stopPropagation();
        presentConfirmAlert({
            header: `Cancel waiting`,
            subHeader: description,
            message: `Are you sure you want to remove yourself from the waiting list?`,
            buttons: [
                {
                    text: 'Nope',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (profilePackage) {
                            _handleWaitingBookingAction(waitingBookingDelete({waitingBookingId: waitingBookingId}))
                        } else {
                            toastStore.showError({message: "Failed to delete waiting booking. No package selected."});
                        }
                    },
                },
            ],
        })
    }, [waitingBookingDelete, profilePackage, toastStore])

    /**
     * Delete an existing booking
     * @param bookingId
     */
    const handleDeleteClicked = useCallback((bookingId: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        event && event.stopPropagation();
        presentConfirmAlert({
            header: `Cancel Booking`,
            subHeader: description,
            message: `Are you sure you want to cancel this booking?`,
            buttons: [
                {
                    text: 'Nope',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (profilePackage) {
                            _handleBookingAction(bookingDelete({bookingId: bookingId}))
                        } else {
                            toastStore.showError({message: "Failed to delete booking. No package selected."});
                        }
                    },
                },
            ],
        })
    }, [bookingDelete, profilePackage, toastStore])

    /**
     * Cancel the attendance for a specific date for a booking
     * @param bookingId
     * @param lessonDateTs
     */
    const handleCancelClicked = useCallback((bookingId: number, lessonDateTs: number, description: string, event: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        event && event.stopPropagation();
        presentConfirmAlert({
            header: `Attendance Cancellation`,
            subHeader: description,
            message: `Are you sure you want to submit a cancellation for this booking?`,

            buttons: [
                {
                    text: 'Nope',
                    role: 'cancel',
                    handler: () => {
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (profilePackage) {
                            _handleBookingAction(bookingCancel({bookingId, lessonDateTs}))
                        } else {
                            toastStore.showError({message: "Failed to cancel booking. No package selected."});
                        }
                    },
                },
            ],
        })

    }, [bookingCancel, profilePackage, toastStore]);

    const handleSlotClick = (slot: Slot) => {
        if (isFetching){ return;}
        setSelectedSlot(slot);
        setIsSlotModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedSlot(undefined);
        setIsSlotModalOpen(false);
    };

    const handleSlotFilterOpenClick = () => {
        setIsSlotFilterModalOpen(true);
    };

    const handleCloseSlotFilterModal = () => {
        setIsSlotFilterModalOpen(false);
    };



    return <SlotsPage
        company={company}
        learner={profile}
        profilePackage={profilePackage}
        availableDates={availableDates}
        slots={filteredSlots}
        bookings={bookings}
        facilities={facilities}
        slotTypes={slotTypes}
        employees={employees}

        onBookClicked={handleBookClicked}
        onWaitClicked={handleWaitClicked}
        onCancelWaitClicked={handleCancelWaitClicked}
        onDeleteClicked={handleDeleteClicked}
        onCancelClicked={handleCancelClicked}
        isFetching={isFetching}
        isChangingBooking={isChangingBooking}
        isInitialLoadComplete={isInitialLoadComplete}

        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        selectedDateIsToday={selectedDateIsToday}
        selectedWeekIndex={selectedWeekIndex}
        weeksAvailableFuture={weeksAvailableFuture}
        weeksAvailablePast={weeksAvailablePast}
        onPreviousWeekClicked={handlePreviousWeek}
        onNextWeekClicked={handleNextWeek}

        isSlotFilterModalOpen={isSlotFilterModalOpen}
        onSlotFilterOpenClick={handleSlotFilterOpenClick}
        onCloseSlotFilterModal={handleCloseSlotFilterModal}

        filterAvailableOnly={filterAvailableOnly}
        setFilterAvailableOnly={setFilterAvailableOnly}
        filterBookedOnly={filterBookedOnly}
        setFilterBookedOnly={setFilterBookedOnly}
        filterSlotType={filterSlotType}
        setFilterSlotType={setFilterSlotType}
        filterFacility={filterFacility}
        setFilterFacility={setFilterFacility}
        filterEmployee={filterEmployee}
        setFilterEmployee={setFilterEmployee}

        selectedSlot={selectedSlot}
        isSlotModalOpen={isSlotModalOpen}
        onSlotClick={handleSlotClick}
        onCloseModal={handleCloseModal}
    />;
};
