import * as React from 'react';
import './customer-page.component.css';
import Page from "../../../components/core/Page";

import {Customer} from "../../../models";
import Input, {FormFieldInputs} from "../../../components/input/Input";
import FormSubmitButton from "../../../components/common/FormSubmitButton";
import {IonButton, IonText} from "@ionic/react";


type CustomerPageProps = {
    customer?: Customer | null;
    selectedId?: any;

    customerForm: any;
    customerFormFieldInputs: FormFieldInputs;
    onSubmitCustomer: (data: any) => void;
    onBackClicked: () => void;
    onSelectProfile: (profileId: number) => void;

    // Loading states
    isFetching: boolean;
    isLoading: boolean;
};

export const CustomerPage: React.FC<CustomerPageProps> = (props) => {
    const {
        customerForm,
        customerFormFieldInputs,
        customer,
        onSubmitCustomer,
        onBackClicked,
        isFetching,
    } = props;
    const {handleSubmit, register, control, formState: {errors}} = customerForm;

    const formRef = React.useRef<HTMLFormElement>(null);

    const triggerSave = () => {
        if (formRef.current) {
            formRef.current.requestSubmit();
        }
    };

    return (
        <Page
            className={"customer-page ion-text-center"}
            title={`${customer?.fullName}`}
            showBackButton={true}
            showMenuButton={false}
            showLoading={isFetching}
            topRightButton={<IonButton className="ion-color text-semi-bold" slot="end" fill="clear"
                                       onClick={() => triggerSave()}
            >Save</IonButton>}
        >
            <>
                {/* Customer Edit Form */}
                <form ref={formRef} onSubmit={handleSubmit(onSubmitCustomer)}>
                    {Object.entries(customerFormFieldInputs).map(([_fieldName, field], index) => (
                        <Input
                            key={index}
                            {...field}
                            control={control}
                            register={register}
                            errors={errors}
                            isDisabled={isFetching}
                        />
                    ))}
                    <FormSubmitButton
                        outerClassName={"ion-text-center ion-margin-top"}
                        disabled={isFetching}
                    >
                        Save
                    </FormSubmitButton>
                </form>

                <IonButton className="ion-margin-bottom" onClick={onBackClicked} fill="clear">
                    Back
                </IonButton>
            </>
        </Page>
    );
};
