import * as React from 'react';
import './terms-of-use.page.css';
import ModalPage from "../../components/core/ModalPage";
import {useModalPageStore} from "../../redux/modal-pages";
import {IonText} from "@ionic/react";

type Props = {
    onClose?: () => void,
};

export const TermsOfUsePage: React.FC<Props> = props => {
    const {onClose} = props;
    const modalPageStore = useModalPageStore();
    const {isOpenTermsOfUse, setIsOpenTermsOfUse} = modalPageStore;

    return (
        <ModalPage className={"terms-of-use-page"}
                   title={"Terms of Use"}
                   isOpen={!!isOpenTermsOfUse}
                   setIsOpen={setIsOpenTermsOfUse}
                   onClose={onClose}
        >
            <div className="ion-padding ion-margin-horizontal">
                <h1>Terms of Use</h1>
                <p>Last updated: 1st March 2025</p>

                <h2>Introduction</h2>
                <p>These Terms of Use ("Terms") govern your access to and use of the swool.io mobile application ("App")
                    provided by Solunetix (Pty) Ltd. ("Solunetix", "we", "us" or "our"). The App is designed to offer
                    clients of businesses and organizations serviced by swool.io ("swool-serviced-businesses") a
                    platform to manage bookings and account history for classes, lessons, and other services. By
                    accessing or using the App, you agree to be bound by these Terms.</p>

                <h2>Eligibility</h2>
                <p>You must be at least 13 years of age to use the App. By using the App, you represent and warrant that
                    you meet this age requirement and that you agree to be bound by these Terms. If you are under the
                    age of 18, you must have permission from a parent or legal guardian to use the App.</p>

                <h2>Privacy</h2>
                <p>Your privacy is important to us. In accordance with the Protection of Personal Information Act
                    (POPIA) in South Africa, we are committed to protecting your personal information. Please review our
                    Privacy Policy, which explains how we collect, use, and disclose your personal information when you
                    use the App, and how we comply with the POPIA regulations.</p>

                <h2>App Access and Use</h2>
                <p>Subject to your compliance with these Terms, Solunetix grants you a limited, non-exclusive,
                    non-transferable, non-sublicensable, and revocable license to access and use the App solely for your
                    personal, non-commercial purposes.</p>

                <h2>User Content</h2>
                <p>By submitting, uploading, or otherwise making available any content through the App ("User Content"),
                    you grant Solunetix a non-exclusive, worldwide, royalty-free, fully paid, sublicensable, and
                    transferable license to use, reproduce, distribute, prepare derivative works of, display, and
                    perform the User Content in connection with the App and Solunetix's business purposes.</p>
                <p>You represent and warrant that you own or have the necessary licenses, rights, consents, and
                    permissions to grant the rights to Solunetix as set forth above.</p>

                <h2>Prohibited Conduct</h2>
                <p>When using the App, you agree not to:</p>
                <ol>
                    <li>violate any applicable laws, rules, or regulations;</li>
                    <li>engage in any activities that interfere with, disrupt, or negatively affect the App or its
                        servers, networks, or other infrastructure;
                    </li>
                    <li>upload, post, or transmit any content that is abusive, threatening, defamatory, obscene, or
                        otherwise objectionable;
                    </li>
                    <li>impersonate any person or entity or falsely state or otherwise misrepresent your affiliation
                        with a person or entity;
                    </li>
                    <li>violate the rights of any third party, including, without limitation, intellectual property
                        rights and privacy rights;
                    </li>
                    <li>engage in any conduct that could damage, disable, overburden, or impair the App or Solunetix's
                        systems or networks.
                    </li>
                </ol>

                <h2>Third-Party Content and Services</h2>
                <p>The App may contain links to third-party websites, resources, or services. Solunetix is not
                    responsible for the content, products, or services available from such third parties, and you assume
                    all risks arising from your use of any such third-party content or services.</p>

                <h2>Disclaimer</h2>
                <p>The App is provided on an "as is" and "as available" basis. Solunetix disclaims all warranties of any
                    kind, whether express or implied, including, but not limited to, the implied warranties of
                    merchantability, fitness for a particular purpose, and non-infringement.</p>

                <h2>Modification and Termination</h2>
                <p>Solunetix reserves the right to modify or discontinue, temporarily or permanently, the App or any
                    features or portions thereof without prior notice. You agree that Solunetix will not be liable for
                    any modification, suspension, or discontinuance of the App. Solunetix also reserves the right to
                    suspend or terminate your access to the App at any time, with or without cause, including for any
                    violation of these Terms.</p>

                <h2>Limitation of Liability</h2>
                <p>To the maximum extent permitted by applicable law, Solunetix shall not be liable for any
                    indirect,
                    incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
                    whether
                    incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses,
                    resulting from your access to or use of, or inability to access or use, the App.</p>
                <p>Solunetix does not provide the services offered by swool-serviced-businesses and is not
                    responsible for any disputes, cancellations, or issues that arise between users and such
                    businesses.</p>

                <h2>Governing Law and Dispute Resolution</h2>
                <p>These Terms and any disputes arising out of or relating to these Terms or your use of the App shall
                    be governed by the laws of South Africa, without regard to its conflict of law provisions.</p>

                <p>Any disputes arising out of or relating to these Terms or the App shall be resolved through good
                    faith negotiations between the parties. If the parties are unable to resolve the dispute through
                    negotiations, either party may initiate legal proceedings in the courts of South Africa.</p>

                <h2>Changes to the Terms of Use</h2>
                <p>Solunetix reserves the right, at its sole discretion, to change or modify these Terms at any
                    time. If we make any material changes to these Terms, we will notify you through the App, by email,
                    or by
                    other means deemed appropriate. Your continued use of the App following any changes to the Terms
                    constitutes your acceptance of the revised Terms.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions or concerns about these Terms of Use, please contact us at:</p>
                <ul>
                    <li>
                        <IonText className="text-semi-bold">Email: &nbsp;</IonText><a
                        href="mailto:hello@solunetix.co.za"
                    >hello@solunetix.co.za</a>
                    </li>
                </ul>

            </div>
        </ModalPage>
    );
};
