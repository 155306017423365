import {createAsyncThunk} from '@reduxjs/toolkit';

import {customersService, profilePackagesService} from '../../services';
import {type} from './state';
import {ApiResponse} from "../../models";
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";

export const getAllProfilePackages = createAsyncThunk<ApiResponse, {customerId?: number, learnerId?: number}>(
    `${type}/getAllProfilePackages`,
    async (args, {rejectWithValue}) => {
        try {
            const {customerId, learnerId} = args;
            return await profilePackagesService.getAll(customerId, learnerId);
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const setSelectedId = createAsyncThunk(
    `${type}/setSelectedId`,
    async (arg: { id: number }) => {
        const {id} = arg;
        const result = await customersService.setSelectedId(id);
        return {
            selectedId: result.id,
        };
    }
);

export const setSelectedCustomerIdAction = createAsyncThunk(
    `${type}/setSelectedCustomerIdAction`,
    async (arg: { id: number }) => {
        const {id} = arg;
        const result = await customersService.setSelectedId(id);
        return {
            selectedId: result.id,
        };
    }
);

export const setSelectedCustomerLearnerId = createAsyncThunk(
    `${type}/setSelectedCustomerLearnerId`,
    async (arg: { learnerId: number }) => {
        const {learnerId} = arg;
        const result = await customersService.setSelectedProfileId(learnerId);
        return {
            selectedLearnerId: result.learnerId,
        };
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);

