import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    addReceivedMessageAction,
    clearAllAction,
    getAllAction,
    sendMessageAction,
    setSelectedIdAction,
} from './actions';
import {
    chatMessagesSelector,
    errorMessageSelector,
    isFetchingSelector, isSendingSelector,
    selectedChatMessageSelector,
    selectedIdSelector, sortedChatMessagesSelector,
} from './selectors';

// Action (service) interfaces
import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {ChatMessage} from "../../models";

export const useChatsStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const chatMessages = useAppSelector(chatMessagesSelector);
    const sortedChatMessages = useAppSelector(sortedChatMessagesSelector);
    const isFetching = useAppSelector(isFetchingSelector);
    const isSending = useAppSelector(isSendingSelector);
    const selectedId = useAppSelector(selectedIdSelector);
    const selectedChatMessage = useAppSelector(selectedChatMessageSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    const getAll = useCallback(
        (args: {customerId?: number, learnerId?: number}) => {
            return dispatch(getAllAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const sendMessage = useCallback(
        (args: { customerId: number; message: string; localId: string }) => {
            return dispatch(sendMessageAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const addMessage = useCallback(
        (args: ChatMessage) => {
            return unwrapResult(dispatch(addReceivedMessageAction(args)));
        },
        [dispatch]
    );


    const setSelectedId = useCallback(
        (args: {id?: number}) => {
            return dispatch(setSelectedIdAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        isSending,
        errorMessage,
        selectedChatMessage,
        chatMessages,
        sortedChatMessages,
        sendMessage,
        addMessage,
        getAll,
        clearAll,
        selectedId,
        setSelectedId,
    } as const;
};
