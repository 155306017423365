import {createSelector} from '@reduxjs/toolkit';

import {adapter, ChatsState, type} from './state';

const {selectAll, selectEntities} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: ChatsState }) => state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const isSendingSelector = createSelector(
    featureStateSelector,
    state => state.isSending
);


export const isFetchingSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    (state) => state.errorMessage
);

export const chatMessagesSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const sortedChatMessagesSelector = createSelector(
    chatMessagesSelector,
    (messages) => [...messages].sort((a, b) => a.sentAtTs - b.sentAtTs)
);


export const selectedChatMessageSelector = createSelector(
    entitiesSelector,
    selectedIdSelector,
    (entities, selectedId) => (selectedId && entities[selectedId]) || undefined
);
