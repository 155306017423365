import {createReducer} from '@reduxjs/toolkit';

import {initialState} from './state';
import {setEnableAnimationAction, setIsOnlineAction,} from './actions';

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(setIsOnlineAction.fulfilled, (state, action) => {
            const {isOnline} = action.payload;
            return {...state, isOnline: isOnline};
        })
        /** HANDLE ACTION ====================================== */
        .addCase(setEnableAnimationAction.fulfilled, (state, action) => {
            const {enabled} = action.payload;
            return {...state, enableAnimation: enabled};
        })
);
