import axios from "axios";
import {apiUrl} from "../../services.config";

const apiClient = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
});


export default apiClient;
