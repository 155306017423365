import React, { useCallback, useEffect, useRef } from 'react';
import { ChatPage } from '../components';
import { v4 as uuid } from 'uuid';
import {useChatsStore} from '../../../redux/chats';
import { useCustomerStore } from '../../../redux/customers';
import {useToastStore} from "../../../redux/toast";

export const ChatPageContainer: React.FC = () => {
    const toastStore = useToastStore();
    const chatStore = useChatsStore();
    const { getAll, sendMessage, isFetching, isSending, sortedChatMessages } = chatStore;
    const customerStore = useCustomerStore();
    const { activeCustomer } = customerStore;
    const refresherRef = useRef<HTMLIonRefresherElement>(null);


    const fetchData = useCallback(() => {
        if (activeCustomer) {
            getAll({ customerId: activeCustomer.id }).finally(() => {
                if (refresherRef.current) {
                    refresherRef.current.complete();
                }
            });
        }
    }, [activeCustomer, getAll]);

    useEffect(() => {
        if (activeCustomer) {
            fetchData();
        }
    }, [activeCustomer, fetchData]);


    const handleSendMessage = (text: string) => {
        if (!activeCustomer || !text || text.trim() === '') return;

        // Generate a local ID and create an optimistic message
        sendMessage({customerId: activeCustomer.id, message: text, localId: uuid()})
            .catch(error => {
                toastStore.showError({ message: 'Failed to send message',});
            });
    };

    return (
        <ChatPage
            isFetching={isFetching}
            isSending={isSending}
            chatMessages={sortedChatMessages}
            companyName={activeCustomer?.company.name ?? ''}
            doRefresh={fetchData}
            refresherRef={refresherRef}
            onSendMessage={handleSendMessage}
        />
    );
};
