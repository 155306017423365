import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    updateAction,
} from './actions';
import {ApiResponse} from "../../models";

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(updateAction.pending, (state, action) => {
            return {...state, isLoading: true, errorMessage: undefined, errors: undefined};
        })
        .addCase(updateAction.fulfilled, (state, action) => {
            // Logic to update local records is in customer reducer
            return {...state, isLoading: false};
        })
        .addCase(updateAction.rejected, (state, action) => {
            const {errorMessage, errors} = action.payload as ApiResponse;
            return {...state, isLoading: false, errorMessage, errors};
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
