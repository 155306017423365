import {createReducer} from '@reduxjs/toolkit';

import {initialState} from './state';
import {
    clearAction,
    setButtonsAction,
    setColorAction,
    setHeaderAction,
    setIsOpenAction,
    setMessageAction,
    showAction,
} from './actions';

export const reducer = createReducer(initialState, builder =>
    builder
        .addCase(showAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(showAction.fulfilled, (state, action) => {
            const {header, message, color, buttons} = action.payload;
            return {...state, isOpen: true, header, message, color, buttons};
        })
        .addCase(showAction.rejected, (state, action) => {
            return {...state, isOpen: false};
        })

        .addCase(setIsOpenAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(setIsOpenAction.fulfilled, (state, action) => {
            const {isOpen} = action.payload;
            return {...state, isOpen: !!isOpen};
        })
        .addCase(setIsOpenAction.rejected, (state, action) => {
            return {...state, isOpen: false};
        })

        .addCase(setButtonsAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(setButtonsAction.fulfilled, (state, action) => {
            const {buttons} = action.payload;
            return {...state, buttons};
        })
        .addCase(setButtonsAction.rejected, (state, action) => {
            return {...state};
        })

        .addCase(setColorAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(setColorAction.fulfilled, (state, action) => {
            const {color} = action.payload;
            return {...state, color};
        })
        .addCase(setColorAction.rejected, (state, action) => {
            return {...state};
        })

        .addCase(setHeaderAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(setHeaderAction.fulfilled, (state, action) => {
            const {header} = action.payload;
            return {...state, header};
        })
        .addCase(setHeaderAction.rejected, (state, action) => {
            return {...state};
        })

        .addCase(setMessageAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(setMessageAction.fulfilled, (state, action) => {
            const {message} = action.payload;
            return {...state, message: message};
        })
        .addCase(setMessageAction.rejected, (state, action) => {
            return {...state};
        })

        .addCase(clearAction.pending, (state, action) => {
            return {...state};
        })
        .addCase(clearAction.fulfilled, (state, action) => {
            return {...state, message: undefined, isOpen: false};
        })
        .addCase(clearAction.rejected, (state, action) => {
            return {...state};
        })


);
