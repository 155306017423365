import React, {FC, ReactElement, useState} from "react";
import {
    IonButton,
    IonCheckbox,
    IonIcon,
    IonInput,
    IonItem,
    IonPopover,
    IonSelect,
    IonSelectOption,
    IonText,
} from "@ionic/react";
import {ErrorMessage} from "@hookform/error-message";
import {TextFieldTypes} from "@ionic/core";
import "./Input.css";
import {Control, Controller} from "react-hook-form";
import {eye, eyeOff, helpCircleOutline} from "ionicons/icons";

export interface InputProps {
    isVisible?: boolean;
    isDisabled?: boolean;
    name: any;
    helpText?: any;
    showLabel?: boolean;
    control?: Control;
    register?: any;
    hasControl?: boolean;
    label?: string;
    render?: any;
    minimum?: number;
    maximum?: number;
    type?:
        | TextFieldTypes
        | "select"
        | "checkbox"
        | "textarea"
        | "one-time-code";
    autofocus?: boolean;
    rules?: any;
    checkboxSetValue?: any;
    options?: { key: string; text: string }[];
    errors?: any;
}

export interface FormFieldInputs {
    [field: string]: InputProps
}

const Input: FC<InputProps> = ({
                                   isVisible = true,
                                   isDisabled = false,
                                   name,
                                   helpText,
                                   control,
                                   register,
                                   render,
                                   type,
                                   minimum,
                                   maximum,
                                   autofocus,
                                   hasControl = true,
                                   label,
                                   options,
                                   errors,
                                   rules,
                               }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showHelpPopover, setShowHelpPopover] = useState<boolean>(false);

    const renderInput = (e: { field: any, fieldState: any, formState: any }) => {
        const {field} = e;
        const {onChange: controllerOnChange, value, ref} = field;

        const handleChange = (event: any) => {
            controllerOnChange(event);
        };

        const toggleShowPassword = (event: any) => {
            event.preventDefault();
            setShowPassword(!showPassword);
        };

        // Determine min/max props for IonInput (only relevant for 'number' or 'date'):
        const applyMin = type === "number" || type === "date" ? minimum : undefined;
        const applyMax = type === "number" || type === "date" ? maximum : undefined;

        // If it's text or URL, we can pass `max` as `maxLength`
        const applyMaxLength = (type === "text" || type === "url") && maximum != null ? maximum : undefined;

        switch (type) {
            case "checkbox":
                return (
                    <IonCheckbox
                        onIonChange={handleChange}
                        checked={value}
                        ref={ref}
                        aria-invalid={errors && errors[name] ? "true" : "false"}
                        aria-describedby={`${name}Error`}
                    />
                );

            case "select":
                return (
                    <IonSelect
                        onIonChange={handleChange}
                        value={value}
                        aria-invalid={errors && errors[name] ? "true" : "false"}
                        aria-describedby={`${name}Error`}
                    >
                        {options &&
                            options.map((optionItem, optionKey) => (
                                <IonSelectOption
                                    key={optionItem.key}
                                    value={optionItem.key}
                                >
                                    {optionItem.text}
                                </IonSelectOption>
                            ))}
                    </IonSelect>
                );

            default:
                return (
                    <>
                        <IonInput
                            disabled={isDisabled}
                            autofocus={autofocus}
                            onIonChange={handleChange}
                            label={label}
                            labelPlacement={"stacked"}
                            placeholder={label}
                            type={(type === "password" && showPassword) ? "text" : type}
                            value={value}
                            min={applyMin}
                            max={applyMax}
                            maxlength={applyMaxLength}
                            aria-invalid={errors && errors[name] ? "true" : "false"}
                            aria-describedby={`${name}Error`}
                            {...register(name)}
                            onKeyUp={(event) => {
                                type === "password" && controllerOnChange(event.currentTarget.value)
                            }}
                        />
                        {type === "password" && (
                            <IonButton fill="clear" onClick={toggleShowPassword}>
                                <IonIcon slot="icon-only" size={"large"} icon={showPassword ? eye : eyeOff}/>
                            </IonButton>
                        )}
                    </>
                );
        }
    };

    const renderNoControl = (render: () => ReactElement) => {
        return render();
    };

    return (
        isVisible ? (
            <>
                <IonItem>
                    {hasControl === false && renderNoControl(render)}
                    {hasControl && (
                        <Controller
                            render={render ?? renderInput}
                            control={control}
                            name={name}
                            rules={rules}
                        />
                    )}
                    {helpText && (
                        <IonButton fill="clear" size={"large"} onClick={() => setShowHelpPopover(true)}>
                            <IonIcon slot="icon-only" icon={helpCircleOutline} />
                        </IonButton>
                    )}
                    <IonPopover
                        className="input-popover"
                        isOpen={showHelpPopover}
                        onDidDismiss={() => setShowHelpPopover(false)}
                    >
                        <div className="input-popover-content">
                            <div className="input-popover-header">
                                <IonIcon className="help-icon" icon={helpCircleOutline}/>
                                <span className="input-popover-label">{label}</span>
                            </div>
                            <div className="input-popover-body">
                                {helpText}
                            </div>
                        </div>
                    </IonPopover>
                </IonItem>
                <IonText color="danger">
                    <ErrorMessage errors={errors} name={name}/>
                </IonText>
            </>
        ) : null
    );
};

export default Input;
