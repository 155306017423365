import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Slot} from '../../models';

export const type = 'slots';

export interface SlotsState extends EntityState<Slot, string> {
    activeProfileId?: string;
    selectedId?: number;
    selectedDrawKey?: string;
    isFetching: boolean;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<Slot, string>({
    selectId: (slot) => `${slot.drawKey}-${slot.id}`,
});

export const initialState: SlotsState = adapter.getInitialState({
    activeProfileId: undefined,
    isFetching: false,
    selectedId: undefined,
    selectedDrawKey: undefined,
});
