import {createReducer} from '@reduxjs/toolkit';

import {adapter, initialState} from './state';
import {
    addOptimisticMessageAction, addReceivedMessageAction,
    clearAllAction,
    clearErrorsAction,
    getAllAction, sendMessageAction,
    setSelectedIdAction, updateMessageAction,
} from './actions';

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllAction.pending, state => {
            return {...state, isFetching: true, isSending: false};
        })
        .addCase(getAllAction.fulfilled, (state, action) => {
            const { results, customerId } = action.payload;
            const { messages } = results;
            let newState = adapter.getInitialState({ ...state, isFetching: false });

            if (!!customerId) {
                let newState = adapter.getInitialState({ ...state, isFetching: false });
                if (!!customerId) {
                    // Get existing chats for the specified customer
                    const existingChats = adapter.getSelectors().selectAll(state).filter((chat) => chat.customerId === customerId);
                    // Create a Set of new chat IDs for faster lookup
                    const newChatIdsSet = new Set(Object.values(messages).map((chat) => chat.id));
                    // Remove chats that are not in the new chats array
                    for (const chat of existingChats) {
                        if (!newChatIdsSet.has(chat.id)) {
                            newState = adapter.removeOne(newState, chat.id);
                        }
                    }
                    // Upsert the new chats
                    newState = adapter.upsertMany(newState, Object.values(messages));
                } else {
                    // Replace all chats with the new chats
                    newState = adapter.setAll(newState, Object.values(messages));
                }
                return newState;
            } else {
                // Replace all chats with the new chats
                newState = adapter.setAll(newState, Object.values(messages));
            }

            return newState;
        })
        .addCase(getAllAction.rejected, state => {
            return {...state, isFetching: false, isSending: false};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(sendMessageAction.pending, state => {
            return {...state, isSending: true};
        })
        .addCase(sendMessageAction.fulfilled, (state, action) => {
            return { ...state, isSending: false };
        })
        .addCase(sendMessageAction.rejected, state => {
            return {...state, isSending: false};
        })

        .addCase(addOptimisticMessageAction, (state, action) => {
            adapter.addOne(state, action.payload);
        })

        // When the API returns, update the optimistic message using localId.
        .addCase(updateMessageAction, (state, action) => {
            const { localId, chatMessage } = action.payload;

            if (!chatMessage || !chatMessage.id) {
                console.warn('Invalid message:', chatMessage);
                return;
            }

            // Find the optimistic message by its unique localId
            const optimisticMessage = Object.values(state.entities)
                .find((msg) => msg?.localId === localId);

            if (optimisticMessage) {
                // Remove the optimistic message (using its unique temporary id)
                adapter.removeOne(state, optimisticMessage.id);
            }

            // Add the confirmed message with its real id
            adapter.addOne(state, { ...chatMessage, pending: false });
        })

        .addCase(addReceivedMessageAction, (state, action) => {
            const chatMessage = action.payload;

            if (!chatMessage || !chatMessage.id) {
                console.warn('Invalid received message:', chatMessage);
                return;
            }

            // Ensure the message is not duplicated
            if (!state.entities[chatMessage.id]) {
                adapter.addOne(state, { ...chatMessage, pending: false });
            }
        })

        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(setSelectedIdAction.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
