import {createSelector} from '@reduxjs/toolkit';

import {adapter, CustomerState, type} from './state';

const {selectAll, selectEntities} = adapter.getSelectors();

const featureStateSelector = (state: { [type]: CustomerState }) =>
    state[type];

const entitiesSelector = createSelector(
    featureStateSelector,
    selectEntities
);

export const selectedIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedId
);

export const selectedProfileIdSelector = createSelector(
    featureStateSelector,
    state => state.selectedProfileId
);

export const isFetchingSelector = createSelector(
    featureStateSelector,
    state => state.isFetching
);

export const errorMessageSelector = createSelector(
    featureStateSelector,
    state => state.errorMessage
);


export const customersSelector = createSelector(
    featureStateSelector,
    selectAll
);

export const customersCountSelector = createSelector(
    featureStateSelector,
    state => state.ids?.length
);

export const activeCustomerSelector = createSelector(
    entitiesSelector,
    selectedIdSelector,
    (entities, id) => id && entities && entities.hasOwnProperty(id) ? entities[id] || null : null
);

export const activeCompanySelector = createSelector(
    activeCustomerSelector,
    customer => customer?.company
);

export const selectedSiteIdSelector = createSelector(
    activeCompanySelector,
    company => company?.siteId
);

export const profilesSelector = createSelector(
    activeCustomerSelector,
    customer => customer?.profiles
);

export const activeProfileSelector = createSelector(
    profilesSelector,
    selectedProfileIdSelector,
    (profiles, profileId) => profiles && profileId ? profiles.find(profile => profile.id === profileId) || null : null
);

export const profileByIdSelector = createSelector(
    [
        profilesSelector,
        // Take the second arg, `profilePackageId`, and forward to the output selector
        (state, profileId: number | undefined): number => profileId || -1
    ],
    // Output selector
    (profiles, profileId) => profiles ? profiles.find(profile => profile.id === profileId) : profiles
)
