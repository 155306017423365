import {createAsyncThunk} from '@reduxjs/toolkit';

import {profilesService, ProfileUpdateData} from '../../services';
import {type} from './state';
import {createClearAllAsyncThunk, createClearErrorAsyncThunk} from "../shared-actions";
import {ApiResponse} from "../../models";


export const updateAction = createAsyncThunk<ApiResponse, { profileId: number, data: ProfileUpdateData }>(
    `${type}/updateAction`,
    async (arg, {rejectWithValue}) => {
        try {
            const {profileId, data} = arg;
            return await profilesService.update(profileId, data);
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const clearErrorsAction = createClearErrorAsyncThunk(type);
export const clearAllAction = createClearAllAsyncThunk(type);
