import {createReducer} from '@reduxjs/toolkit';

import {adapter, CustomerState, initialState} from './state';
import {
    clearAllAction,
    clearErrorsAction,
    getAllCustomers,
    getCustomerById,
    setSelectedCustomerProfileId,
    setSelectedIdCustomer,
    updateAction,
} from './actions';
import {getAllFinancialTransactions} from "../financial-transactions";
import {ApiResponse, Customer} from "../../models";
import {updateAction as ProfileUpdateAction} from "../profiles";
import {
    BaseExtraAttribute, BaseExtraAttributeValue,
} from "../../models/extra-attributes.model";

const handlePending = (state: CustomerState) => ({
    ...state,
    isFetching: true,
    errorMessage: undefined,
});
const mergeAttributesWithValues = (
    attributes: BaseExtraAttribute[],
    values: BaseExtraAttributeValue[]
): (BaseExtraAttribute & { value: string | null })[] => {
    return attributes.map(attr => {
        const foundValue = values.find(val => val.attributeId === attr.id)?.value;
        const finalValue = foundValue ?? attr.defaultValue ?? null;
        return {
            ...attr,
            value: finalValue
        };
    });
};

export const reducer = createReducer(initialState, builder =>
    builder
        /** HANDLE ACTION ====================================== */
        .addCase(getAllCustomers.pending, handlePending)
        .addCase(getAllCustomers.fulfilled, (state, action) => {
            const { customers } = action.payload.results;
            const customerArray = Object.values(customers || {});
            const normalizedCustomers = customerArray.map((customer: Customer) => {
                // Map learners to profiles (fallback if needed)
                const profiles = customer.profiles || customer.learners || [];

                // If you have extra attributes & values, merge them
                const mergedExtraAttributes = mergeAttributesWithValues(
                    customer.company?.customerExtraAttributes || [],
                    customer.extraAttributeValues || []
                );

                // Merge profile extra attributes & values for each learner
                const mergedProfiles = profiles.map(profile => ({
                    ...profile,
                    extraAttributes: mergeAttributesWithValues(
                        customer.company?.learnerExtraAttributes || [],
                        profile.extraAttributeValues?.filter(value => value.learnerId === profile.id) || []
                    )
                }));

                return {
                    ...customer,
                    profiles: mergedProfiles,
                    extraAttributes: mergedExtraAttributes
                };
            });
            return adapter.setAll({ ...state, isFetching: false }, normalizedCustomers);
        })
        .addCase(getAllCustomers.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(getCustomerById.pending, handlePending)
        .addCase(getCustomerById.fulfilled, (state, action) => {
            const {customer} = action.payload.results;
            return adapter.updateOne({...state, isFetching: false}, {id: customer.id, changes: customer});
        })
        .addCase(getCustomerById.rejected, (state, action) => {
            const {errorMessage} = action.payload as ApiResponse;
            return {...state, isFetching: false, errorMessage};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setSelectedIdCustomer.pending, state => {
            return {...state};
        })
        .addCase(setSelectedIdCustomer.fulfilled, (state, action) => {
            const {selectedId} = action.payload;
            return {...state, selectedId: selectedId};
        })
        .addCase(setSelectedIdCustomer.rejected, state => {
            return {...state};
        })

        /** HANDLE ACTION ====================================== */
        .addCase(setSelectedCustomerProfileId.pending, state => {
            return {...state};
        })
        .addCase(setSelectedCustomerProfileId.fulfilled, (state, action) => {
            const {selectedProfileId} = action.payload;
            return {...state, selectedProfileId: selectedProfileId};
        })
        .addCase(setSelectedCustomerProfileId.rejected, state => {
            return {...state};
        })

        /** HANDLE UPDATE ACTION ====================================== */
        .addCase(updateAction.pending, handlePending)
        .addCase(updateAction.fulfilled, (state, action) => {
            const { customer } = action.payload.results;
            return adapter.updateOne(
                { ...state, isFetching: false },
                { id: customer.id, changes: customer }
            );
        })
        .addCase(updateAction.rejected, (state, action) => {
            const { errorMessage } = action.payload as ApiResponse;
            return { ...state, isFetching: false, errorMessage };
        })

        /** EXTERNAL ACTIONS =================================== */
        .addCase(getAllFinancialTransactions.pending, handlePending)
        .addCase(getAllFinancialTransactions.fulfilled, (state, action) => {
            const {balances} = action.payload.results;
            return adapter.updateMany({...state, isFetching: false}, Object.values(balances).map((balance) => {
                return {
                    id: balance.customerId,
                    changes: {
                        balance: balance.balance,
                        balanceDisplay: balance.balanceDisplay,
                        balanceBroughtForwardDateDisplay: balance.balanceBroughtForwardDateDisplay,
                        balanceBroughtForwardDisplay: balance.balanceBroughtForwardDisplay,
                    }
                };
            })
            );
        })
        .addCase(getAllFinancialTransactions.rejected, (state, action) => {
            // const {errorMessage} = action.payload as ApiResponse;
            // Let financial handle its own error message
            return {...state, isFetching: false};
        })

        /** EXTERNAL ACTIONS =================================== */
        .addCase(ProfileUpdateAction.fulfilled, (state, action) => {
            const {profile} = action.payload.results;
            const customerIdFromPayload = profile.customerId; // or however you know which customer to update
            if (!customerIdFromPayload || !state.entities[customerIdFromPayload]) return;

            const currentCustomer = state.entities[customerIdFromPayload];
            if (!currentCustomer?.profiles) return;

            const profileIndex = currentCustomer.profiles.findIndex(p => p.id === profile.id);
            if (profileIndex !== -1) {

                // Merge profile extra attributes & values for each learner
                const mergedProfileExtraAttributes = mergeAttributesWithValues(
                    currentCustomer.company?.learnerExtraAttributes || [],
                    profile.extraAttributeValues?.filter((value: any) => value.learnerId === profile.id) || []
                );

                currentCustomer.profiles[profileIndex] = {
                    ...profile,
                    extraAttributes: mergedProfileExtraAttributes
                };
            }
        })


        /** HANDLE NON-API ACTIONS ====================================== */
        .addCase(clearAllAction.fulfilled, () => adapter.removeAll({...initialState}))
        .addCase(clearErrorsAction.fulfilled, state => ({...state, errorMessage: undefined}))
);
