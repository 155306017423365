import * as React from 'react';

import {SettingsPageContainer} from './containers';

interface Props {}

export const SettingsPage: React.FC<Props> = () => {
    return (
        <SettingsPageContainer />
    );
};
