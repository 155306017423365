export const type = 'system';

export interface SystemState {
    isOnline: boolean;
    enableAnimation: boolean;
}

export const initialState: SystemState = {
    isOnline: true,
    enableAnimation: true,
};
