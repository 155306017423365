/**
 * Convert a date to a timestamp
 * @param date
 */
export const dateToTs = (date: Date): number => Math.floor(date.getTime() / 1000);

/**
 * Get the start of a
 * @param date
 */
export const dateStartOfWeek = (date: Date): Date => {
    const day = date.getDay();
    if( day !== 1 )
        date.setHours(-24 * (day - 1));
    return date;
}
/**
 * Get the end of a
 * @param date
 */
export const dateEndOfWeek = (date: Date): Date => {
    const day = date.getDay() - 7;
    if( day !== 0 )
        date.setHours(-24 * day);
    return date;
}

/**
 * Convert a timestamp to a date
 * @param timestamp
 */
export const tsToDate = (timestamp: number) => new Date(timestamp * 1000);

/**
 * new Date object set to 00:00 today
 */
export const todayDate = (): Date => {
    const workingDate = new Date();
    workingDate.setHours(0, 0, 0, 0);
    return workingDate
}

/**
 * Timestamp of today set to 00:00 today
 */
export const todayTs = (): number => dateToTs(todayDate())

/**
 * Get the Day of Week number for the given timestamp
 * @param timestamp
 */
export const tsToDayOfWeekNum = (timestamp: number): number => {
    const dt = tsToDate(timestamp);
    return dt.getUTCDay();
};

/**
 * dateToFormat('yyyy-mm-dd hh:ii:ss');
 * @param date
 * @param format
 */
export const dateToFormat = (date: Date, format: string = 'yyyy-mm-dd'): string => {
    const yyyy = date.getFullYear().toString();
    format = format.replace(/yyyy/g, yyyy)
    const yy = date.getFullYear().toString().slice(2,4);
    format = format.replace(/yy/g, yy)
    const mmm = date.toLocaleString('default', {month: 'short'});
    format = format.replace(/mmm/g, (mmm[1]?mmm:"0"+mmm[0]));
    const mm = (date.getMonth()+1).toString();
    format = format.replace(/mm/g, (mm[1]?mm:"0"+mm[0]));
    const ddd = date.toLocaleString('default', {weekday: 'short'})
    format = format.replace(/ddd/g, ddd);
    const DD = date.toLocaleString('default', {weekday: 'narrow'})
    format = format.replace(/DD/g, DD);
    const dd  = date.getDate().toString();
    format = format.replace(/dd/g, (dd[1]?dd:"0"+dd[0]));
    const hh = date.getHours().toString();
    format = format.replace(/hh/g, (hh[1]?hh:"0"+hh[0]));
    const ii = date.getMinutes().toString();
    format = format.replace(/ii/g, (ii[1]?ii:"0"+ii[0]));
    const ss = date.getSeconds().toString();
    format = format.replace(/ss/g, (ss[1]?ss:"0"+ss[0]));

    return format;
};

export class Ages {
    date: Date = new Date();

    constructor(dateOrTs?: Date|number) {
        this.date = typeof dateOrTs === "number" ? tsToDate(dateOrTs) : (dateOrTs && new Date(dateOrTs)) || this.date;
    }

    // Convert stored UTC timestamp to local time
    toLocalTime = (format: string = "hh:ii"): string => {
        const localOffset = this.date.getTimezoneOffset() * 60000; // Offset in milliseconds
        const localDate = new Date(this.date.getTime() - localOffset); // Adjust for timezone

        return localDate.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Ensures 24-hour format
        });
    };

    // Modify date =====================================================================================

    startOfDay = (): Ages => {
        this.date.setHours(0, 0, 0, 0);
        return this;
    };

    startOfWeek = (): Ages => {
        this.date = dateStartOfWeek(this.date);
        return this;
    };
    
    endOfWeek = (): Ages => {
        this.date = dateEndOfWeek(this.date);
        return this;
    };

    addDays = (days= 1): Ages => {
        this.date.setHours(24 * days)
        return this;
    };

    addWeeks = (weeks= 1): Ages => {
        this.date.setHours(24 * 7 * weeks)
        return this;
    };

    subDays = (days= 1): Ages => {
        this.date.setHours(-24 * days)
        return this;
    };

    subWeeks = (weeks= 1): Ages => {
        this.date.setHours(-24 * 7 * weeks)
        return this;
    };

    // Comparison
    equalsDay = (eqDate: Ages): boolean => {
        return this.startOfDay().toTs() === eqDate.startOfDay().toTs();
    };

    lt = (ltDate: Ages): boolean => {
        return this.toTs() < ltDate.toTs();
    };

    lte = (ltDate: Ages): boolean => {
        return this.toTs() <= ltDate.toTs();
    };

    gt = (ltDate: Ages): boolean => {
        return this.toTs() > ltDate.toTs();
    };

    gte = (ltDate: Ages): boolean => {
        return this.toTs() >= ltDate.toTs();
    };


    // Output & Formatting =============================================================================

    toFormat = (format: string = 'yyyy-mm-dd'): string => dateToFormat(this.date, format);

    toTs = (): number => dateToTs(this.date);

    toString = (): string => this.toFormat();

    copy = (): Ages => new Ages(new Date(this.date.getTime()));


}
