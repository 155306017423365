import * as Forms from "../forms";
import { FormFieldInputs } from "../../components/input/Input";

export interface FormFields extends Forms.FormFields {
    firstName: string;
    lastName: string;
    // gender: number;
    // dateOfBirth?: string | null;
    // allowSocialMediaSharing: boolean;
}

export const formFieldInputs: FormFieldInputs = {
    firstName: {
        name: "firstName",
        label: "First Name",
        type: "text",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 50,
                message: "Cannot be more than 50 characters",
            },
        },
    },
    lastName: {
        name: "lastName",
        label: "Last Name",
        type: "text",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 50,
                message: "Cannot be more than 50 characters",
            },
        },
    },
    // dateOfBirth: {
    //     name: "dateOfBirth",
    //     label: "Date of Birth",
    //     type: "date",
    //     rules: {
    //         required: "This field is required",
    //     },
    // },
    // allowSocialMediaSharing: {
    //     name: "allowSocialMediaSharing",
    //     label: "Allow Social Media Sharing",
    //     type: "checkbox",
    // },
};

export interface Form extends Forms.Form {
    fields: FormFields;
}

export const initialState: Form = {
    ...Forms.formInitialState,
    fields: {
        firstName: "",
        lastName: "",
        gender: 0,
        dateOfBirth: null,
        allowSocialMediaSharing: false,
    },
};

export const formName = "profile";
