import React, {useState} from 'react';
import {IonFooter, IonItem, IonList, IonTextarea, IonToolbar} from '@ionic/react';
import Page from '../../../components/core/Page';
import {Ages} from '../../../utils/ages';
import {ChatMessage} from '../../../models';
import './chat-page.component.css';
import {sendOutline} from "ionicons/icons";
import IconButton from "../../../components/common/IconButton/IconButton";
import {useScrollToBottom} from "../../../components/common/ScrollToBottom";
import SkeletonText from "../../../components/common/SkeleltonText";

type Props = {
    isFetching: boolean;
    isSending: boolean;
    companyName: string;
    chatMessages: ChatMessage[];
    doRefresh: (event: CustomEvent) => void;
    refresherRef: React.RefObject<HTMLIonRefresherElement> | ((ref: HTMLIonRefresherElement | null) => void);
    onSendMessage: (message: string) => void;
};

export const ChatPage: React.FC<Props> = ({
                                              isFetching,
                                              isSending,
                                              chatMessages,
                                              companyName,
                                              doRefresh,
                                              refresherRef,
                                              onSendMessage,
                                          }) => {
    const [newMessage, setNewMessage] = useState('');
    const messagesEndRef = useScrollToBottom([chatMessages]);

    const handleSend = () => {
        if (!isSending && !isFetching && newMessage.trim() !== '') {
            const newMsg = newMessage;
            setNewMessage('');
            onSendMessage(newMsg);
        }
    };

    return (
        <Page
            title="Chat"
            showMenuButton={false}
            showBackButton={true}
            showLoading={isFetching && !isSending && !newMessage && (!chatMessages || chatMessages.length === 0)}
            backRouterLink="/home"
            doRefresh={doRefresh}
            refresherRef={refresherRef}
            footer={
                <IonFooter>
                    <IonToolbar>
                        <IonItem>
                            <IonTextarea
                                placeholder="Type your message"
                                value={newMessage}
                                rows={1}
                                autoGrow
                                autoFocus
                                onIonInput={(e) => setNewMessage(e.detail.value!)}
                                onKeyDown={(e) => {
                                    if (!isSending && !isFetching && e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSend();
                                    }
                                }}
                            />
                            <IconButton
                                slot="end"
                                onClick={handleSend}
                                icon={sendOutline}
                                color="primary"
                                loadingIcon={sendOutline}
                                isLoading={isSending || isFetching}
                            />
                        </IonItem>
                    </IonToolbar>
                </IonFooter>
            }
        >
            <IonList className="chat-content">
                <div className="chat-messages">
                    {chatMessages.map((msg) => {
                        const isUser = msg.sender === "app";
                        return (
                            <div key={msg.id} className={`chat-bubble ${isUser ? "user-bubble" : "admin-bubble"} ${msg.pending ? "bubble-pending" : ""}`}>
                                <div className="bubble-content">
                                    <p>{msg.message}</p>
                                    <span className="bubble-time">{new Ages(msg.sentAtTs).toLocalTime()}</span>
                                </div>
                            </div>
                        );
                    })}
                    {(!isFetching && chatMessages.length === 0) && (
                        <div className="no-messages">No messages</div>
                    )}
                    {isFetching && chatMessages.length > 0 && (
                        <SkeletonText style={{ marginTop: 10 }} />
                    )}
                </div>
                <div ref={messagesEndRef} />
            </IonList>

        </Page>
    );
};
