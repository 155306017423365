import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
} from '@ionic/react';
import * as React from 'react';

import Page from "../../../components/core/Page";
import './settings-page.component.css';
import TabFooter from "../../../components/core/TabFooter";
import {Customer} from "../../../models";
import {useHistory} from "react-router";

type Props = {
    customer?: Customer | null;
    canEditProfile: boolean;
    canEditContact: boolean;
};

export const SettingsPage: React.FC<Props> = props => {
    const {
        customer,
        canEditProfile,
        canEditContact,
    } = props;

    const history = useHistory();

    const navigateTo = (path: string) => {
        history.push(path);
    };

    return (
        <Page className={"settings-page"}
              title={'Settings'}
              footer={customer && <TabFooter activeRoute={'/settings'} customer={customer}/>}
              showMenuButton={true}
              showBackButton={false}
              showLoading={false}
        >

            {/* Customer Details Section */}
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Customer</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList>
                        <IonItem button onClick={() => navigateTo('customer')}>
                            <IonLabel><h2>{customer?.fullName}</h2></IonLabel>
                        </IonItem>
                    </IonList>
                </IonCardContent>
            </IonCard>

            {/* Profiles Section */}
            {customer?.profiles && customer?.profiles.length > 0 && (<IonCard>
                <IonCardHeader>
                    <IonCardTitle>Profiles</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList>
                        {customer?.profiles.map((profile) => (
                            <IonItem key={profile.id} button onClick={() => navigateTo(`profile/${profile.id}`)}>
                                <IonLabel>{profile.fullName}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>)}

            {/* Contacts Section */}
            {customer?.contacts && customer?.contacts.length > 0 && (<IonCard>
                <IonCardHeader>
                    <IonCardTitle>Contacts</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonList>
                        {customer?.contacts.map((contact) => (
                            <IonItem key={contact.id} button onClick={() => navigateTo('contacts')}>
                                <IonLabel>{contact.firstName}</IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>)}
        </Page>
    );
};
