import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {setEnableAnimationAction, setIsOnlineAction,} from './actions';

import {enableAnimationSelector, isOnlineSelector,} from './selectors';

import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";

export const useSystemStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isOnline = useAppSelector(isOnlineSelector);
    const enableAnimation = useAppSelector(enableAnimationSelector);

    const setEnableAnimation = useCallback(
        (args: {enabled: boolean}) => {
            return dispatch(setEnableAnimationAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    const setIsOnline = useCallback(
        (args: {isOnline: boolean}) => {
            return dispatch(setIsOnlineAction(args)).then(unwrapResult);
        },
        [dispatch]
    );

    return {
        enableAnimation,
        setEnableAnimation,
        isOnline,
        setIsOnline,
    } as const;
};
