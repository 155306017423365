import {unwrapResult} from '@reduxjs/toolkit';
import {useCallback} from 'react';

import {
    clearAllAction,
    clearErrorsAction,
    updateAction,
} from './actions';
import {
    errorMessageSelector,
    isFetchingSelector,
    isLoadingSelector,
} from './selectors';
import {useAppDispatch, useAppSelector} from "../hooks";
import {AppDispatch} from "../store";
import {ProfileUpdateData} from "../../services";

export const useProfilesStore = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const isFetching = useAppSelector(isFetchingSelector);
    const isLoading = useAppSelector(isLoadingSelector);
    const errorMessage = useAppSelector(errorMessageSelector);

    // const bookingsByProfilePackageId = useCallback(
    //     (profilePackageId: string ) => {
    //         useAppSelector((state) => bookingsByProfilePackageIdSelector(state, profilePackageId))
    //         return bookingsByProfilePackageIdSelector;
    //     },
    //     []
    // );

    // const getAll = useCallback(
    //     () => {
    //         return dispatch(getAllProfiles()).then(unwrapResult);
    //     },
    //     [dispatch]
    // );

    // const getById = useCallback(
    //     (id: number) => {
    //         return dispatch(getProfileById(id)).then(unwrapResult);
    //     },
    //     [dispatch]
    // );


    const update = useCallback(
        (arg: { profileId: number, data: ProfileUpdateData }) => {
            return dispatch(updateAction(arg)).then(unwrapResult);
        },
        [dispatch]
    );


    const clearAll = useCallback(
        () => {
            return dispatch(clearAllAction()).then(unwrapResult);
        },
        [dispatch]
    );

    const clearErrors = useCallback(
        () => {
            return dispatch(clearErrorsAction()).then(unwrapResult);
        },
        [dispatch]
    );


    return {
        isFetching,
        isLoading,
        errorMessage,
        // getAll,
        // getById,
        update,
        clearAll,
        clearErrors,
    } as const;
};
