import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { ProfilePage } from '../components';
import {useProfilesStore} from '../../../redux/profiles';
import { useToastStore } from '../../../redux/toast';
import {
    formFieldInputs as profileFixedFormFieldInputs,
    FormFields as ProfileFormFields,
} from "../../../forms/profiles/profile.form";
import {useCustomerStore} from "../../../redux/customers";
import {useHistory} from "react-router";
import {convertExtraAttributesToFormFields} from "../../../utils/convertExtraAttributesToFormFields";

type Props = {
    id: number;
};

export const ProfilePageContainer: React.FC<Props> = ({ id }) => {
    const history = useHistory();
    const toastStore = useToastStore();
    const { showError, showSuccess, clear: toastClear } = toastStore;

    const customerStore = useCustomerStore();
    const { setSelectedProfileId, activeProfile: profile, activeCustomer: customer } = customerStore;

    const profileStore = useProfilesStore();
    const {
        errorMessage,
        clearErrors: profileClearErrors,
        // getProfile,
        update,
        isLoading,
        isFetching,
    } = profileStore;

    const profileForm = useForm<ProfileFormFields>({
        mode: 'onSubmit',
        defaultValues: {
            // gender: 0,
            // date_of_birth: null,
            first_name: '',
            last_name: '',
            // allow_social_media_sharing: false,
        }
    });

    const profileFormFieldInputs: any = {
        ...profileFixedFormFieldInputs,
        ...(profile ? convertExtraAttributesToFormFields(profile.extraAttributes) : {}),
    };


    const { setValue, setError: formSetError, clearErrors: formClearErrors } = profileForm;

    useEffect(() => {
        profileClearErrors();
        formClearErrors();
        setSelectedProfileId({profileId: id})
    }, [id, profileClearErrors, formClearErrors, setSelectedProfileId]);

    useEffect(() => {
        if(profile){
            setValue("firstName", profile.firstName);
            setValue("lastName", profile.lastName);
            // setValue("dateOfBirth", profile.dateOfBirth);
            // setValue("allowSocialMediaSharing", profile.allowSocialMediaSharing);

            if (profile.extraAttributes && Array.isArray(profile.extraAttributes)) {
                profile.extraAttributes.forEach(attr => {
                    setValue(`extra_attribute_${attr.id}`, attr.value || '');
                });
            }
        }
    }, [profile, setValue]);

    useEffect(() => {
        if (errorMessage) {
            toastClear().then(() => {
                showError({ message: errorMessage });
                formClearErrors();
            });
        }
    }, [errorMessage, toastClear, showError, formClearErrors]);

    const onSelectProfile = async (profileId: number) => {
        profileClearErrors();
        formClearErrors();
        history.replace(`/profile/${profileId}`)
    }

    const onSubmitProfile = async (data: ProfileFormFields) => {
        if (!profile){
            if (!errorMessage){
                showError({message: 'Invalid profile.'});
            }
            return;
        }

        const updateData: any = {
            first_name: data.firstName,
            last_name: data.lastName,
        };

        if (profile?.extraAttributes?.length) {
            updateData.extra_attributes = profile.extraAttributes.reduce((acc: any, attr: any) => {
                acc[attr.id] = {
                    attribute_id: attr.id,
                    value: data[`extra_attribute_${attr.id}`] || '',
                };
                return acc;
            }, {});
        }

        update({
            profileId: profile.id,
            data: updateData,
        })
            .then((res) => {
                showSuccess({message: res.message || 'Profile updated successfully!', duration: 2000}).then(() => {
                    history.goBack();
                });
            })
            .catch((res) => {
                if (!errorMessage){
                    showError({message: 'Could not update profile.'});
                }
            })
            .finally(() => {

            });
    };

    const onBackClicked = async () => {
        Promise.all([
            formClearErrors,
            profileClearErrors,
        ]).then(() => {
            history.goBack();
        })
    }

    return (
        <ProfilePage
            customer={customer}
            profile={profile}
            profileForm={profileForm}
            profileFormFieldInputs={profileFormFieldInputs}
            onSelectProfile={onSelectProfile}
            onSubmitProfile={onSubmitProfile}
            onBackClicked={onBackClicked}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    );
};
