// import { InputProps, FormFieldInputs } from "./formTypes"; // Adjust the import path as needed
import {Control} from "react-hook-form";
import {FormFieldInputs, InputProps} from "../components/input/Input";
import {BaseExtraAttribute, ExtraAttributeType} from "../models/extra-attributes.model"; // Assuming you're using react-hook-form

/**
 * Converts extraAttributes into a structure suitable for your custom Input components.
 * Includes handling of min/max constraints based on the attribute type.
 */
export const convertExtraAttributesToFormFields = (
    extraAttributes: (BaseExtraAttribute & { value: string | null })[],
    control?: Control
): FormFieldInputs => {
    const formFields: FormFieldInputs = {};

    if (!extraAttributes || !Array.isArray(extraAttributes)) {
        return formFields;
    }

    extraAttributes.forEach((attr) => {
        const fieldName = `extra_attribute_${attr.id}`;

        const field: InputProps = {
            name: fieldName,
            label: attr.label,
            helpText: attr.helpText || "",
            type: mapAttributeTypeToFieldType(attr.type),
            isDisabled: false, // Adjust if needed
            rules: { required: attr.required ? `${attr.label} is required` : false },
            control: control,
        };

        // 1. Handle default value
        if (attr.value !== undefined && attr.value !== null) {
            field.rules.defaultValue = parseInt(`${attr.value}`);
        }

        // 2. Handle min/max constraints
        //    - For text fields, we'll use `maxLength`.
        //    - For number/date, we'll use `min` and `max`.
        if (attr.type === ExtraAttributeType.TEXT) {
            // Text
            if (attr.maximum) {
                field.maximum = parseInt(`${attr.maximum}`); // Typically used as HTML `maxLength`
            }
        } else if (attr.type === ExtraAttributeType.NUMBER || attr.type === ExtraAttributeType.DATE) {
            // Number (6) or Date (7) can use 'min' and 'max'
            if (attr.minimum !== null && attr.minimum !== undefined) {
                field.minimum = parseInt(`${attr.minimum}`);
            }
            if (attr.maximum !== null && attr.maximum !== undefined) {
                field.maximum = parseInt(`${attr.maximum}`);
            }
        }

        // 3. Handle Dropdown options (type = 2)
        if (attr.type === ExtraAttributeType.DROPDOWN && attr.options) {
            field.options = attr.options.map((option) => ({
                key: option.id.toString(),
                text: option.option,
            }));
        }

        // Add the field config to our formFields
        formFields[fieldName] = field;
    });

    return formFields;
};

// Helper to map attribute types to input field types
const mapAttributeTypeToFieldType = (
    attributeType: number
): InputProps["type"] => {
    switch (attributeType) {
        case ExtraAttributeType.TEXT: // Text
            return "text";
        case ExtraAttributeType.DROPDOWN: // Dropdown
            return "select";
        case ExtraAttributeType.CHECKBOX: // Checkbox
            return "checkbox";
        case ExtraAttributeType.SPACER: // Spacer (not a form field, maybe a render function)
            return "textarea";
        case ExtraAttributeType.URL: // URL
            return "url";
        case ExtraAttributeType.NUMBER: // Number
            return "number";
        case ExtraAttributeType.DATE: // Date
            return "date";
        default:
            return "text";
    }
};
