// Base interface for the attribute definition
export interface BaseExtraAttribute {
    id: number;
    label: string;
    type: ExtraAttributeType;
    helpText?: string;
    required: boolean;
    minimum?: number;
    maximum?: number;
    defaultValue?: string | null;
    options?: BaseExtraAttributeOption[];
}

// Base interface for dropdown or multi-select options
export interface BaseExtraAttributeOption {
    id: number;
    attributeId: number;
    option: string;
}

// Base interface for storing the value of an extra attribute
export interface BaseExtraAttributeValue {
    id: number;
    attributeId: number;
    value: string | null;
}

// Enum for attribute types
export enum ExtraAttributeType {
    TEXT = 1,
    DROPDOWN = 2,
    CHECKBOX = 3,
    SPACER = 4,
    URL = 5,
    NUMBER = 6,
    DATE = 7,
}

export interface CustomerExtraAttribute extends BaseExtraAttribute {}

export interface CustomerExtraAttributeOption extends BaseExtraAttributeOption {}

export interface CustomerExtraAttributeValue extends BaseExtraAttributeValue {
    customerId: number;
}

export interface CustomerMergedExtraAttributeValue extends CustomerExtraAttributeValue {
    attributeId: number;
}


export interface ProfileExtraAttribute extends BaseExtraAttribute {}

export interface ProfileExtraAttributeOption extends BaseExtraAttributeOption {}

export interface ProfileExtraAttributeValue extends BaseExtraAttributeValue {
    learnerId: number;
}

export interface ProfileMergedExtraAttributeValue extends ProfileExtraAttributeValue {
    attributeId: number;
}
