import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Customer} from '../../models';

export const type = 'customer';

export interface CustomerState extends EntityState<Customer, number> {
    isFetching: boolean;
    selectedId: number | null;
    selectedProfileId: number | null;
    activeCustomer: Customer | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<Customer>({
});

export const initialState: CustomerState = adapter.getInitialState({
    isFetching: false,
    selectedId: null,
    selectedProfileId: null,
    activeCustomer: null,
    errorMessage: undefined,
});
