import {ApiResponse, Customer, Holiday} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface getAllResponse extends ApiResponse {
    results: {
        customers: Customer[],
        holidays: Holiday[]
    }
}

export interface getByIdResponse extends ApiResponse {
    results: {
        customer: Customer,
        holidays: Holiday[]
    }
}

export interface CustomerUpdateData {
    first_name: string;
    last_name: string;
    email?: string;
    financial_email?: string;
}

class CustomersService {

    async getAll(): Promise<getAllResponse> {

        return await apiClient
            .get(this.baseUrl)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve customers data.")
            )
    }

    async getById(id: number): Promise<getByIdResponse> {

        return await apiClient
            .get(`${this.baseUrl}/${id}`)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to retrieve customer data.")
            )
    }

    async update(customerId: number, data: CustomerUpdateData): Promise<ApiResponse> {
        return await apiClient
            .post(`${this.baseUrl}/${customerId}`, data)
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to update profile")
            );
    }


    async setSelectedId(id: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({id: id});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    async setSelectedProfileId(profileId: number): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({profileId: profileId});
            })
        }
        await fakePromise();
        return await fakePromise();
    }


    constructor(private readonly baseUrl: string) {
    }
}

export const customersService = new CustomersService(
    '/customers'
);
