import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {ChatMessage} from "../../models";

export const type = 'chats';

export interface ChatsState extends EntityState<ChatMessage, number> {
    isFetching: boolean;
    isSending: boolean;
    selectedId: number | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<ChatMessage>({
});

export const initialState: ChatsState = adapter.getInitialState({
    isFetching: false,
    isSending: false,
    selectedId: null,
    errorMessage: undefined,
});
