import * as React from 'react';

import {CustomerPageContainer} from './containers';

interface RouterParams {}

export const CustomerPage: React.FC<RouterParams> = (props) => {

    return (
        <CustomerPageContainer />
    );
};
