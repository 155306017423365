import {apiUrl} from "../services.config";


class SystemsService {

    async setEnabled(enabled: boolean): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({enabled: enabled});
            })
        }
        await fakePromise();
        return await fakePromise();
    }


    async setIsOnline(isOnline: boolean): Promise<any> {
        const fakePromise = () => {
            return new Promise(resolve => {
                resolve({isOnline: isOnline});
            })
        }
        await fakePromise();
        return await fakePromise();
    }

    constructor(private readonly baseUrl: string) {
    }
}

export const systemsService = new SystemsService(
    apiUrl + '/systems'
);
