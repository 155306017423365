import * as Forms from "../forms";
import { FormFieldInputs } from "../../components/input/Input";

export interface FormFields extends Forms.FormFields {
    firstName: string;
    lastName: string;
    email?: string;
    financialEmail?: string;
}

export const formFieldInputs: FormFieldInputs = {
    firstName: {
        name: "firstName",
        label: "First Name",
        type: "text",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 50,
                message: "Cannot be more than 50 characters",
            },
        },
    },
    lastName: {
        name: "lastName",
        label: "Last Name",
        type: "text",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 50,
                message: "Cannot be more than 50 characters",
            },
        },
    },
    email: {
        name: "email",
        label: "Email",
        type: "email",
        rules: {
            required: "This field is required",
            maxLength: {
                value: 253,
                message: "Cannot be more than 253 characters",
            },
        },
    },
    financialEmail: {
        name: "financialEmail",
        label: "Financial Email",
        type: "email",
        helpText: "Where we will send documents related to finance. Leave this blank if it's the same as 'email'",
        rules: {
            maxLength: {
                value: 253,
                message: "Cannot be more than 253 characters",
            },
        },
    },
};

export interface Form extends Forms.Form {
    fields: FormFields;
}

export const initialState: Form = {
    ...Forms.formInitialState,
    fields: {
        firstName: "",
        lastName: "",
        email: "",
        financialEmail: "",
    },
};

export const formName = "customer";
