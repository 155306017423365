import {ApiAuth, ApiResponse, User} from "../models";
import apiClient from "./api/apiClient";
import {handleApiError} from "./api/apiErrorHandler";

export interface LoginApiResponse extends ApiResponse {
    results: { apiAuth: ApiAuth, user: User};
}
export interface LoginApiRequest {
    username: string,
    password: string,
}

interface MeResponse extends ApiResponse {
    results: { user: User };
}

interface RegisterFcmTokenResponse extends ApiResponse {
    results: { user: User };
    fcmToken: string;
}

interface DeregisterFcmTokenResponse extends ApiResponse {
    results: { user: User };
    fcmToken: string;
}

class AuthService {
    async login(args: LoginApiRequest): Promise<LoginApiResponse> {
        return await apiClient
            .post(this.baseUrl + "/login", {...args})
            .then(
                (response) => {
                    if (response.status !== 200) {
                        return handleApiError("Authentication failed. Invalid Email or Password");
                    }
                    const {tokenType, token} = response.data.results;
                    apiClient.defaults.headers.common['Authorization'] = `${tokenType} ${token}`;
                    return response.data;
                },
                (error) => handleApiError(error, "Authentication failed.")
            )
    }

    async getUser(): Promise<MeResponse> {
        return await apiClient
            .get(this.baseUrl + "/me")
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Failed to fetch user")
            )
    }

    async logout(deviceUuid?: string): Promise<any|null> {
        return await apiClient
            .post(this.baseUrl + "/logout", {deviceUuid})
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Logout failed")
            )
    }

    async registerFcmToken(fcmToken: string, deviceUuid:string, platform: string ): Promise<RegisterFcmTokenResponse> {
        return await apiClient
            .post(this.baseUrl + "/register-fcm", {
                platform,
                deviceUuid,
                token: fcmToken,
                canBeApn: "no",
            })
            .then(
                (response) => ({...response.data, fcmToken: fcmToken}),
                (error) => handleApiError(error, "Notifications token could not be registered")
            )
    }

    async deregisterFcmToken(fcmToken: string): Promise<DeregisterFcmTokenResponse> {
        return await apiClient
            .post(this.baseUrl + "/deregister-fcm", {
                token: fcmToken
            })
            .then(
                (response) => response.data,
                (error) => handleApiError(error, "Notifications token could not be deregistered")
            )

    }

    constructor(private readonly baseUrl: string) {
    }
}

export const authService = new AuthService('/auth');
