import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {persistReducer, persistStore} from 'redux-persist'

import * as system from './system';
import * as auth from './auth';
import * as users from './users';
import * as toast from './toast';
import * as bookings from './bookings';
import * as waitingBookings from './waiting-bookings';
import * as holidays from './holidays';
import * as customers from './customers';
import * as chats from './chats';
import * as communications from './communications';
import * as profiles from './profiles';
import * as profilePackages from './profile-packages';
import * as financialTransactions from './financial-transactions';
import * as slots from './slots';
import * as modalPages from './modal-pages';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";

/**
 * Reducer
 */
const reducer = combineReducers({
    [auth.type]: auth.reducer,
    [users.type]: users.reducer,
    [bookings.type]: bookings.reducer,
    [waitingBookings.type]: waitingBookings.reducer,
    [communications.type]: communications.reducer,
    [chats.type]: chats.reducer,
    [customers.type]: customers.reducer,
    [financialTransactions.type]: financialTransactions.reducer,
    [holidays.type]: holidays.reducer,
    [modalPages.type]: modalPages.reducer,
    [profiles.type]: profiles.reducer,
    [profilePackages.type]: profilePackages.reducer,
    [slots.type]: slots.reducer,
    [system.type]: system.reducer,
    [toast.type]: toast.reducer,
});

/**
 * MIddleware
 */
const middleware = {
    thunk: true,
    immutableCheck: true,
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
};

/**
 * Persist Store
 */
const persistConfig = {
    key: 'root',
    storage
};


const persistedReducer = persistReducer(persistConfig, reducer);

/**
 * Store
 */
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(middleware),
    devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store)

/**
 * Types
 */
export type State = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;


