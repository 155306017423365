export interface NotificationData {
    type: string;
    chatMessage?: string;
    customerId?: string | number;
    // add any extra fields you might have in your data payload
}

export interface HandlerOptions {
    data: NotificationData;
    customers?: { id: number }[];
    setSelectedCustomerId: (arg: { id: number; }) => Promise<{ selectedId: any; }>
    history: { push: (url: string) => void };
    currentLocation?: string;
    addMessage?: (message: any) => void; // Replace 'any' with your ChatMessage type if available
}

/**
 * Handles push notification actions by checking the type and performing the
 * appropriate navigation and state updates.
 */
export const handleNotificationAction = async ({
                                                   data,
                                                   customers,
                                                   setSelectedCustomerId,
                                                   history,
                                                   currentLocation,
                                                   addMessage,
                                               }: HandlerOptions) => {

    switch (data.type) {
        case 'chatMessage': {
            if (data.chatMessage) {
                try {
                    const parsedMessage = JSON.parse(data.chatMessage);
                    if (parsedMessage.customerId) {
                        addMessage && addMessage(parsedMessage);
                        if (customers && customers.some((customer) => customer.id === parsedMessage.customerId)){
                            await setSelectedCustomerId({id: parsedMessage.customerId});
                            if (currentLocation !== '/chat') {
                                history.push('/chat');
                            }
                        }
                    }
                } catch (error) {
                    console.error('Failed to parse chatMessage:', error);
                }
            }
            break;
        }
        default: {
            // Fallback logic if a customerId is provided in the root of data
            if (customers && data.customerId) {
                const customerId = parseInt(data.customerId as string, 10);
                const customerExists = customers.some((customer) => customer.id === customerId);
                if (customerExists) {
                    await setSelectedCustomerId({id: customerId});
                    if (currentLocation !== '/active-customer') {
                        history.push('/active-customer');
                    }
                }
            }
            break;
        }
    }
};
