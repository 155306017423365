import {createEntityAdapter, EntityState} from '@reduxjs/toolkit';

import {Profile} from '../../models';

export const type = 'profile';

export interface ProfileState extends EntityState<Profile, number> {
    isFetching: boolean;
    isLoading: boolean;
    selectedId: number | null;
    selectedLearnerId: number | null;
    activeProfile: Profile | null;
    errorMessage?: string;
}

export const adapter = createEntityAdapter<Profile>({
});

export const initialState: ProfileState = adapter.getInitialState({
    isFetching: false,
    isLoading: false,
    selectedId: null,
    selectedLearnerId: null,
    activeProfile: null,
    errorMessage: undefined,
});
