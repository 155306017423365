import {createAsyncThunk} from '@reduxjs/toolkit';

import {type} from './state';
import {ToastButton, ToastOptions} from "@ionic/react";

export const setIsOpenAction = createAsyncThunk(
    `${type}/setIsOpenAction`,
    async (isOpen: boolean) => {
        return {
            isOpen
        };
    }
);

export const setMessageAction = createAsyncThunk(
    `${type}/setMessageAction`,
    async (message: string) => {
        return {
            message
        };
    }
);

export const setHeaderAction = createAsyncThunk(
    `${type}/setHeaderAction`,
    async (header: string) => {
        return {
            header
        };
    }
);

export const setButtonsAction = createAsyncThunk(
    `${type}/setButtonsAction`,
    async (buttons: (ToastButton | string)[]) => {
        return {
            buttons
        }
    }
);


export const setColorAction = createAsyncThunk(
    `${type}/setColorAction`,
    async (color: string) => {
        return {
            color
        }
    }
);


export const showAction = createAsyncThunk(
    `${type}/showAction`,
    async (toastOptions: ToastOptions) => {
        return {
            ...toastOptions
        }
    }
);

export const clearAction = createAsyncThunk(
    `${type}/clearAction`,
    async () => {
        const fakePromise = () =>
            new Promise((resolve) => {
                setTimeout(() => resolve({}), 100);
            });
        await fakePromise();
        return {};
    }
);

